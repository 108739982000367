import BrandCenter from '@view/BrandCenter'
import Product_Detail from '@view/BrandCenter/Detail'

const routes_BrandCenter = [
  {
    path: '/brand',
    component: BrandCenter,
    isCache: false
  },
  {
    path: '/product/detail/:id',
    component: Product_Detail,
    isCache: false
  }
]

export default routes_BrandCenter