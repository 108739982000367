import React, { useEffect } from 'react';
//HashRouter,支持#识别,http://localhost:8080/#/Page3/
// import { HashRouter as Router, Route } from 'react-router-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Routes from '@router/router'
import { isMobile } from '@utils';
import { Environment, HostPC_Dev, HostPC_Pro, HostMobile_Dev, HostMobile_Pro } from '@utils/constant';

const App = (props) => {
  useEffect(() => {
    let { href } = window.location
    if (Environment == 'production') {
      let toUrl = ''
      if (!isMobile()) {
        if (href.includes(HostMobile_Dev)) {
          toUrl = href.replace(HostMobile_Dev, HostPC_Dev)
        } else if (href.includes(HostMobile_Pro)) {
          toUrl = href.replace(HostMobile_Pro, HostPC_Pro)
        }
        if (toUrl) {
          window.location.replace(toUrl)
        }
      }
    }
  }, [])
  return <Router >
    {Routes}
  </Router>
}
export default App;