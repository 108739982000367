// 解决create-react-app在ie中打开页面空白的兼容性问题:https://blog.csdn.net/qq_39471200/article/details/92655322,
// npm install react-app-polyfill
// 1. 最后重新运行项目发现还是空白但是浏览器没有报错，把node_moudles删掉重新安装后就可以正常打开了。
// 2. polyfill一定要放在react和react-dom之前
import "core-js/es";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
// [Violation] Added non-passive event listener to a scroll-blocking ‘mousewheel‘ event.
// import 'default-passive-events';

import React from 'react';
import ReactDOM from 'react-dom';
// 导入通用css文件
import '@css/common.scss';
// [与后台系统一样的]第三方富文本组件Quill_Editor所需的css文件
import '@css/Quill_Editor/quill.bubble.css';
import '@css/Quill_Editor/quill.core.css';
import '@css/Quill_Editor/quill.snow.css';

// 导入基本swiper样式(安装:npm install --save swiper@4.5.1)
import 'swiper/dist/js/swiper.js';
import 'swiper/dist/css/swiper.min.css';

// 引入antd-mobile样式
import 'antd-mobile/dist/antd-mobile.css';  // or 'antd-mobile/dist/antd-mobile.less'

import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
