import css from './index.scss';

const LabelTag = (props) => {

  return <div className={css.LabelTag}>
    <div className={css.colorBlock}></div>
    <div className={css.title}>
      <span className={css.span1}>{props.span1}</span>
      <span className={css.span2}>{props.span2}</span>
    </div>
  </div>
}

export default LabelTag