import AboutSibu from '@/view/AboutSibu'
import Sibu_Notice from '@/view/Sibu_Notice'
import NoticeList from '@/view/Sibu_Notice/NoticeList'
import EnterSibu from '@/view/EnterSibu'

const routes_AboutSibu = [
  {
    path: '/about',
    component: AboutSibu,
    isCache: false
  },
  {
    path: '/notice',
    component: Sibu_Notice,
    isCache: false
  },
  {
    path: '/notice/list',
    component: NoticeList,
    isCache: false
  },
  {
    path: '/general',
    component: EnterSibu,
    isCache: false
  }
]

export default routes_AboutSibu