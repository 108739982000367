import css from './index.scss';
import imageTool from "@utils/imageTool";
import SectionTag from '@component/SectionTag';
import React, { useEffect, useState } from 'react';
import VideoCom from '@component/VideoCom';

const Sibu_Introduction = (props) => {
  let {
    title_EN = '',
    title_CN = '',
    data = {}
  } = props
  return <div className={css.Sibu_Introduction}>
    <div className={css.video_item}>
      <VideoCom
        videoUrl={props.data?.videoUrl}
        posterUrl={''}></VideoCom>
    </div>
    <div className={css.content}>
      <SectionTag title_EN={title_EN} title_CN={title_CN}></SectionTag>
      <div className={css.text_item}>
        {
          data?.intro?.split('\n').map((i, i_index) => {
            return <span key={i_index}>{i}<br /></span>
          })
        }
      </div>
    </div>
  </div>
}

export default Sibu_Introduction


