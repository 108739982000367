import React, { Component, useRef, useState, useCallback } from 'react';
import css from './index.scss';
// 导入基本swiper组件
import Swiper from "swiper";
import { withRouter } from "react-router-dom";
import imageTool from "@utils/imageTool";
import Pagination from '@component/Pagination';

class CommendList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0,
      swiper: null
    };
    this.setSlideIndex = (index) => {
      this.setState({
        slideIndex: index
      })
    }
    this.initSwiper = () => {
      let _self = this
      let { datalist } = this.props
      setTimeout(() => {
        let tt = new Swiper("#swiper_products", {
          slidesPerView: 1,//同屏显示多少个swiper滑块
          autoplay: {
            disableOnInteraction: false,//不写这行的话，用户滑动后会默认暂停。
          },//自动轮播开启
          direction: 'horizontal',
          mousewheel: false,
          pagination: {
            el: '.swiper-pagination-h',
            clickable: true
          },
          spaceBetween: 15,// 与下一屏的空白距离
          initialSlide: 0,//默认展示第几个滑块
          loop: true,//是否开启无限轮播
          lazy: {
            loadPrevNext: true,
          },
          observer: true,//修改swiper自己或子元素时，自动初始化swiper
          observeParents: true,//修改swiper的父元素时，自动初始化swiper
          observeSlideChildren: true,
          on: {
            slideChangeTransitionEnd: function () {
              // console.log(this.realIndex)//点击的slide索引
              _self.setSlideIndex(this.realIndex)
            }
          }
        });
        _self.setState({
          swiper: tt,
        })
      }, 1000) //不加延时的话 loop: true 不生效, 可能是需要等待渲染完成
    }
  }
  componentDidMount() {
    this.initSwiper()
  }
  render() {
    let props = this.props
    let { datalist } = this.props
    let { slideIndex, swiper } = this.state
    return datalist && datalist.length > 0 ? <div className={css.CommendList}>
      <div className={`${css.SwiperContainer} swiper-container`} id="swiper_products">
        <div className={css.pagination}>
          <Pagination
            current={slideIndex}
            totalPage={datalist.length}
            divider={'/'}
          ></Pagination>
        </div>
        <div className={`${css.SwiperWrapper} swiper-wrapper`}>
          {
            datalist.map((video, v_index) => {
              return <div className={`${css.SwiperSlide} swiper-slide`} key={`product_${v_index}`}>
                <div className={css.product_item} onClick={() => {
                  props.history.push('/video/' + video.id)
                }}>
                  <div className={css.img}>
                    <img src={imageTool.adjustWidth(video.coverImage, 375)} alt=""></img>
                    <div className={css.video_mask} >
                      <div className={css.play_button}></div>
                    </div>
                  </div>
                </div>
              </div>
            })
          }
        </div>
      </div>
    </div> : null
  }
}

export default withRouter(CommendList);
