import React, { Suspense, Spinner, useState, useEffect } from 'react';
import css from './index.scss';
import { Link } from 'react-router-dom';
import Header from '@component/Header';
import { getIndexData, getMenuData } from '@utils/api/common';
import Horizontal_swiper from './Horizontal_swiper';
import Digital_business from './Digital_business';
import Product_slide from './Product_slide';
import Latest_news from './Latest_news';
import Video_slide from './Video_slide';
import Partner_slide from './Partner_slide';
import Footer from '@component/Footer';
import cssParse from '@utils/cssParse'
import ScrollTopTool from '@/component/ScrollTopTool';
import transitImg from '@images/Home/cut-transit@3x.png';
const Home = props => {

  let [indexData, setIndexData] = useState(null)

  useEffect(() => {
    getIndexData().then((data) => {
      setIndexData(data)
    })
    getMenuData(true)
  }, [])

  if(!indexData) return null
  return (
    <div className={css.Home}>
      <Header />
      <div style={{
        marginTop: '0',
        position: 'relative'
      }}>
        {/* 渐变色背景 */}
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: `translate(-50%, -50%)`,
          width: '100vw',
          height: '100%',
          backgroundColor: 'transparent',
          backgroundImage: `url(${transitImg})`,
          backgroundSize: '100% 100%'
        }}></div>
        <Horizontal_swiper mainData={indexData.bannerVoList} />
      </div>
      <div style={{ marginTop: cssParse.px2vw(15) }}>
        <Digital_business span1="数字" span2="微商" mainData={indexData.bannerTwoVo} />
      </div>
      <div>
        <Product_slide span1="产品" span2="中心" mainData={indexData.productSetVoList} />
      </div>
      <div style={{ marginTop: cssParse.px2vw(15) }}>
        <Latest_news span1="新闻" span2="动态" mainData={indexData.indexNewsVoList} />
      </div>
      <div style={{ marginTop: cssParse.px2vw(15) }}>
        <Video_slide span1="视频" span2="中心" mainData={indexData.indexVideoVoList} />
      </div>
      <div style={{ marginTop: cssParse.px2vw(15) }}>
        <Partner_slide span1="战略" span2="伙伴" mainData={indexData.indexPartnerVoList} />
      </div>
      <div style={{ marginTop: cssParse.px2vw(18) }}>
        <Footer />
      </div>
      <ScrollTopTool></ScrollTopTool>
    </div>
  );
}

export default Home