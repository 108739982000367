export const IndexMenuData = 'Index_Menu_Data' // 首页数据

export const HotProducts_Data = 'productSetVoList' // 热销产品数据
export const HotProducts_ID = 0 // 热销产品分类ID
export const HotProducts_Name = '热销产品' // 热销产品分类名称

export const Environment = process.env.NODE_ENV // production 或 development
export const HostPC_Dev = 'new.sibu.cn' // pc端官网地址-测试
export const HostPC_Pro = 'sibu.cn' // pc端官网地址-生产
export const HostMobile_Dev = 'new-m.sibu.cn' // 移动端官网地址-测试
export const HostMobile_Pro = 'm.sibu.cn' // 移动端官网地址-生产