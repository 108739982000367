import css from './index.scss';
import Header from '@/component/Header';
import Footer from '@/component/Footer';
import ScrollTopTool from '@/component/ScrollTopTool';
import React, { useEffect, useState, use } from 'react';
import { useHistory, useLocation } from 'react-router';
import { scrollSmoothToY, scrollSmoothToX } from '@utils';
import { formatSearchToJson } from '@utils';
import Request from '@utils/request';
import { HotProducts_ID, HotProducts_Name } from '@utils/constant';
import { getIndexData } from '@utils/api/common';
import ProductList from './ProductList';
import Tabs from './Tabs';

const BrandCenter = (props) => {
  let history = useHistory()
  let location = useLocation()
  let [Data, setData] = useState({})
  let categoryId = formatSearchToJson(location.search).category || 0
  let [categoryData, setCategoryData] = useState({})
  let [categoryIndex, setCategoryIndex] = useState(-1)

  useEffect(() => {
    // getIndexData().then((indexData) => {
    Request.get('/website/site/brandCenter').then(res => {
      if (res.errorCode == 0) {
        // 【热销产品】不再需要使用首页的产品
        // res.result.goodsCategoryVoList.unshift({
        //   id: HotProducts_ID,
        //   catName: HotProducts_Name,
        //   goodsVoList: indexData.productSetVoList
        // })
        setData(res.result)
      }
    })
    // })
  }, [])

  useEffect(() => {
    if (!Data.goodsCategoryVoList) return
    let targetIndex = Data.goodsCategoryVoList.findIndex(item => item.id == categoryId)
    if (targetIndex == -1) {
      targetIndex = 0 // 如果找不到categoryId对应的分类，就显示第一个分类
    }
    setCategoryIndex(targetIndex)
    let targetData = Data.goodsCategoryVoList[targetIndex]
    setCategoryData(targetData)
  }, [categoryId, Data])

  return <div className={css.BrandCenter}>
    <Header></Header>
    <div className={css.content}>
      {
        categoryIndex != -1 ? <Tabs
          initIndex={categoryIndex}
          options={Data.goodsCategoryVoList}
          onChange={(option, index) => {
            setCategoryData(option)
            setCategoryIndex(index)
          }}></Tabs> : null
      }
      {
        categoryData && categoryData.goodsVoList ? <div className={css.ProductList}>
          <ProductList mainData={categoryData.goodsVoList} catId={categoryData.id}></ProductList>
        </div> : null
      }
    </div>
    <ScrollTopTool></ScrollTopTool>
    <Footer></Footer>
  </div>
}
export default BrandCenter;