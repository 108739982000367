import css from './index.scss';
import SectionTag from '@component/SectionTag';

import QRcode_Group from '@images/QRcode/QRcode_Group.jpg';
import QRcode_Youxuan from '@images/QRcode/QRcode_Youxuan.jpg';

const Sibu_FollowUs = (props) => {
  let {
    title_EN = '',
    title_CN = '',
    data = {}
  } = props
  return <div className={css.Sibu_FollowUs}>
    <SectionTag title_EN={title_EN} title_CN={title_CN}></SectionTag>
    <div className={css.QRcodeList}>
      <div className={css.box}>
        <img src={QRcode_Group} className={css.QRcode} alt="思埠集团"></img>
        <div className={css.QRcodeText}>扫码关注思埠集团公众号</div>
      </div>
      <div className={css.box}>
        <img src={QRcode_Youxuan} className={css.QRcode} alt="思埠优选"></img>
        <div className={css.QRcodeText}>扫码关注思埠优选公众号</div>
      </div>
    </div>
  </div>
}

export default Sibu_FollowUs


