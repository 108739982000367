// import message from './message'

/* 公用的一些方法 */ 
// params对象转换成字符串
export const buildParams=(params)=>{
  if(!params) return ''
  var str = ''
  for(var key in params){
    str += key+'='+params[key]+"&"
  }
  str = str.substr(0,str.length-1)
  return "?"+str
}
// 获取url后指定字段的值
export const getQueryString= ( field ) => {
  var href = window.location.href;
  var reg = new RegExp( '[?&]' + field + '=([^&#]*)', 'i' );
  var string = reg.exec(href);
  return string ? decodeURIComponent(string[1]) : null;
}
// 获取url后的所有参数
export const getAllQuery = ()=>{
  var query = window.location.search.substring(1);
  var vars = query.split("&")
  let params = vars.reduce((obj,item)=>{
    var pair = item.split("=")
    obj[pair[0]] = decodeURIComponent(pair[1])
    return obj
  },{})
  return params
}
// 获取cookie
export const getCookie=(key)=>{//获取cookie方法
  /*获取cookie参数*/
  var getCookie = document.cookie.replace(/[ ]/g,"");  //获取cookie，并且将获得的cookie格式化，去掉空格字符
  var arrCookie = getCookie.split(";")  //将获得的cookie以"分号"为标识 将cookie保存到arrCookie的数组中
  var tips;  //声明变量tips
  for(var i=0;i<arrCookie.length;i++){   //使用for循环查找cookie中的tips变量
    var arr=arrCookie[i].split("=");   //将单条cookie用"等号"为标识，将单条cookie保存为arr数组
    if(key==arr[0]){  //匹配变量名称，其中arr[0]是指的cookie名称，如果该条变量为tips则执行判断语句中的赋值操作
      tips=arr[1];   //将cookie的值赋给变量tips
      break;   //终止for循环遍历
    } 
  }
  return tips;
}
// 生产url链接后的查询参数
export const buildUrlParams=(params)=>{
  if(!params) return ''
  var str = ''
  for(var key in params){
    str += key+'='+encodeURIComponent(params[key])+"&"
  }
  str = str.substr(0,str.length-1)
  return "?"+str
}
// 函数防抖
export const debounce=(fn, interval = 300)=>{
  let timeout = null;
  return function () {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn.apply(this, arguments)
    }, interval)
  }
}

export const numberToPrice=(num, options = {
  showDecimals:2,// 格式化小数位
  showSymbol : true
})=>{
  const showSymbol = options.showSymbol;
  const showDecimals = options.showDecimals;
  return num.toLocaleString('zh', {
    style: showSymbol ? 'currency' : undefined,
    currency: showSymbol ? 'CNY' : undefined,
    maximumFractionDigits: showDecimals,
  });
}


// 获得角度
export const getAngle = (angx, angy) => {
  return Math.atan2(angy, angx) * 180 / Math.PI;
}

// 根据起点终点返回方向 1向上 2向下 3向左 4向右 0未滑动
export const getDirection = (startx, starty, endx, endy) => {
  let angx = endx - startx;
  let angy = endy - starty;
  let result = 'no';
  // 如果滑动距离太短
  if (Math.abs(angx) < 2 && Math.abs(angy) < 2) {
    return result;
  }
  let angle = getAngle(angx, angy);
  if (angle >= -135 && angle <= -45) {
    result = 'up';
  } else if (angle > 45 && angle < 135) {
    result = 'down';
  } else if ((angle >= 135 && angle <= 180) || (angle >= -180 && angle < -135)) {
    result = 'left';
  } else if (angle >= -45 && angle <= 45) {
    result = 'right';
  }
  return result;
}
export const beforeUpload=(file)=>{
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    // message.error('你只能上传 JPG/PNG 类型的图片!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    // message.error('上传图片不能大于2M！');
  }
  return isJpgOrPng && isLt2M;
}






