import React, { Component, useRef, useState, useCallback } from 'react';
import css from './index.scss';
import { withRouter } from "react-router-dom";
import imageTool from "@utils/imageTool";
import LabelTag from '@component/LabelTag';
// 导入基本swiper组件
import Swiper from "swiper";
import SectionTag from '@component/SectionTag';

import btn_prev from '@images/Arrows/btn_prev@2x.png';
import btn_next from '@images/Arrows/btn_next@2x.png';
class Partner_slide extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    setTimeout(() => {
      new Swiper("#swiper_honor", {
        slidesPerView: 1,//同屏显示多少个swiper滑块
        autoplay: {
          disableOnInteraction: false,//不写这行的话，用户滑动后会默认暂停。
          delay: 4000,
        },//自动轮播开启
        direction: 'horizontal',
        mousewheel: false,
        pagination: {
          el: '.swiper-pagination-h',
          clickable: true
        },
        spaceBetween: 15,// 与下一屏的空白距离
        initialSlide: 0,//默认展示第几个滑块
        loop: true,//是否开启无限轮播
        lazy: {
          loadPrevNext: true,
        },
        navigation: {
          prevEl: '#prev',
          nextEl: '#next'
        },
        observer: true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,//修改swiper的父元素时，自动初始化swiper
        on: {
          slideChangeTransitionEnd: function () {
            // console.log(this.realIndex)//点击的slide索引
          }
        }
      });
    }, 300) //不加延时的话 loop: true 不生效, 可能是需要等待渲染完成
  }

  render() {
    let props = this.props
    let {
      mainData = [],
      span1 = '',
      span2 = ''
    } = props
    let row = 3
    let column = 2
    let pageSize = row * column
    let totalPage = Math.ceil(mainData.length / pageSize)
    let pages = mainData ? parseInt(mainData.length / pageSize) + 1 : 0
    return <div className={css.Sibu_Honor}>
      <div className={css.LabelTag}>
        <LabelTag span1={props.span1} span2={props.span2}></LabelTag>
      </div>
      <div className={css.content}>
        <div className={css.swiper}>
          {
            totalPage > 1 ? <div>
              <img className={`${css.pagination_btn} ${css.prev}`} id="prev" src={btn_prev} alt=""></img>
              <img className={`${css.pagination_btn} ${css.next}`} id="next" src={btn_next} alt=""></img>
            </div> : null
          }
          <div className={`${css.SwiperContainer} swiper-container`} id="swiper_honor">
            <div className={`${css.SwiperWrapper} swiper-wrapper`}>
              {
                Array.from(Array(totalPage), (e, i) => {
                  return <div className={`${css.SwiperSlide} swiper-slide`} key={`slideItem_${i}`}>
                    {
                      mainData.slice(i * pageSize, (i + 1) * pageSize).map((item, i_index) => {
                        return <div className={css.item} key={`item_${i_index}`}>
                          <img className={css.logo} src={imageTool.adjustWidth(item.img, 155 * 2)} />
                          <div className={css.text}>{item.companyName}</div>
                        </div>
                      })
                    }
                  </div>
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default withRouter(Partner_slide);