import css from './index.scss';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import Header from '@/component/Header';
import Footer from '@/component/Footer';
import ScrollTopTool from '@/component/ScrollTopTool';
import Request from '@utils/request';
import { formatSearchToJson } from '@utils';
import { scrollSmoothToY } from '@utils';
import imageTool from "@utils/imageTool";
import VideoIntro from '../component/VideoIntro';
import { ListViewProvider, ListContext } from '@context/listView'
import moment from 'moment';
import LabelTag from '@component/LabelTag';



const VideoList = (props) => {
  let history = useHistory()
  let location = useLocation()
  let params = useParams()
  let catId = params.catId || ''

  const List = () => {
    let { listData, allData } = useContext(ListContext)
    if (listData.length == 0) return <div className={css.list}>暂无视频</div>
    let { catName } = allData
    return <div className={css.com}>
      {
        catName ? <LabelTag span1={catName.substr(0, 2)} span2={catName.substr(2)}></LabelTag> : null
      }
      <div className={css.datalist}>
        {
          listData.map((video, v_index) => {
            return <div className={css.item} key={video.id} onClick={() => {
              history.replace('/video/' + video.id)
              scrollSmoothToY(document.body || document.documentElement, 0)
            }}>
              <div className={css.top}>
                <img className={css.img} src={imageTool.adjustWidth(video.coverImage, 375)} alt="" />
                <div className={css.video_mask} >
                  <div className={css.play_button}></div>
                </div>
              </div>
              <div className={css.bot}>
                <div className={css.title}>{video.videoName}</div>
              </div>
            </div>
          })
        }
      </div>
    </div>
  }

  if (!catId) return null
  return <div className={css.VideoList}>
    <Header></Header>
    <div className={css.content}>
      <div className={css.List}>
        <ListViewProvider url={'/website/site/listVideoByCatId'} pageDataParam={'videoList'} defaultParams={{ pageSize: 12, catId: catId }}>
          <List />
        </ListViewProvider>
      </div>
    </div>
    <ScrollTopTool></ScrollTopTool>
    <Footer></Footer>
  </div>
}

export default VideoList;