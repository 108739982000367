import React, { Suspense, lazy, useState, useCallback, useContext, useEffect, useRef } from 'react'
import { useLocation, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import css from './index.scss';
import { sessionStorage } from '@utils/store';

import img_magnifier from '@images/Header/magnifier@2x.png';

const MenuConfig_Base = [
  {
    label: '首页',
    path: '/',
    isShowSubMenu: false,
    subMenu: []
  },
  {
    label: '新闻资讯',
    path: '/news',
    isShowSubMenu: false,
    subMenu: []
  },
  {
    label: '关于思埠',
    path: '/about',
    isShowSubMenu: false,
    subMenu: [
      {
        name: '思埠介绍',
        path: '/about',
      },
      {
        name: '走进思埠',
        path: '/general',
      },
      {
        name: '思埠公告',
        path: '/notice/list',
      },
      {
        name: '视频中心',
        path: '/videoCenter',
      }
    ]
  },
  {
    label: '品牌中心',
    path: '/brand',
    isShowSubMenu: false,
    subMenu: []
  },
  {
    label: '在线查询',
    path: '/query',
    isShowSubMenu: false,
    subMenu: [
      // 防伪查询 http://report.sibumbg.com/html/fangwei/#/
      {
        name: '防伪查询',
        path: '/query/quality',
      },
      // 授权查询 http://shouquan.sibu.cn/tel.html?v=1 (0831移除)
      // {
      //   name: '授权查询',
      //   path: '/query/grant',
      // }
    ]
  }]

const Menu = props => {
  let history = useHistory()
  let location = useLocation()
  let data = props.data
  let [MenuConfig, setMenuConfig] = useState(MenuConfig_Base)
  // let [keyword, setKeyword] = useState('')

  let [m_index, setM_index] = useState(-1)
  let [s_index, setS_index] = useState(-1)
  // 根据当前页面的 url 匹配相应的菜单选项，并高亮选项
  const setMenuHighlight = useCallback((temp) => {
    temp.forEach((menu, m_index) => {
      let url = location.pathname + location.search
      let menuUrl = menu.path + (menu.search || '')
      if (menuUrl === url || menuUrl === location.pathname) {
        setM_index(m_index)
        setS_index(-1)
      }
      if (menu.subMenu && menu.subMenu.length > 0) {
        menu.subMenu.forEach((sub, s_index) => {
          let subMenuUrl = sub.path + (sub.search || '')
          if (subMenuUrl === url) {
            setM_index(m_index)
            setS_index(s_index)
          }
        })
      }
    })
  }, [location.pathname, location.search]);

  // 根据首页数据，设置菜单的二级菜单名称和链接
  const setMenuData = useCallback((data) => {
    if (!data) return

    let temp = [...MenuConfig_Base]
    // 配置【新闻资讯（分类）】子菜单
    temp[1].subMenu = []
    data.newsCategoryList?.forEach((news_cat, c_index) => {
      temp[1].subMenu.push({
        name: news_cat.catName,
        path: '/news',
        search: '?catId=' + news_cat.catId
      })
    })

    // 配置【思埠公告】最新的公告id
    temp[2].subMenu[2].path = '/notice/list'

    // 配置【品牌中心】
    temp[3].subMenu = []
    data.categoryVoList?.forEach((category, b_index) => {
      temp[3].subMenu.push({
        name: category.catName,
        path: '/brand',
        search: '?category=' + category.catId
      })
    })
    temp.forEach((item)=>{
      item.isShowSubMenu = false
    })
    setMenuConfig(temp)
    setMenuHighlight(temp)
  }, [setMenuHighlight]);

  // 展开二级菜单
  const openSubMenu = (index) => {
    let temp = [...MenuConfig]
    temp[index].isShowSubMenu = !temp[index].isShowSubMenu
    setMenuConfig(temp)
  }

  // 点击一级/二级菜单的事件回调
  const handleClick = (path = '/', search = '', state = {}, menu_index = -1, sub_index = -1) => {
    if (sub_index == -1 && menu_index !== 0) {
      // 若点击的是一级菜单且不为[首页]，展开二级子菜单
      openSubMenu(menu_index)
    } else {
      // 路由route的path和search
      // console.log(path)
      // console.log(search)
      // 浏览器location的path和search[当前页面]
      // console.log(location)
      // console.log(location.pathname)
      // console.log(location.search)
      props.switchShow()
      setM_index(menu_index)
      setS_index(sub_index)
      try {
        if (path === location.pathname) {
          history.replace(path + search)
        } else {
          history.push(path + search)
        }

      } catch (error) {
        console.log(error)
      }
    }
  }

  // 判断菜单名称是否包含搜索框关键字
  // const isContains = (string, key) => {
  //   return key && string.indexOf(key) > -1
  // }

  useEffect(() => {
    if (!data) return
    setMenuData(data)
    // setKeyword('')
  }, [data, setMenuData])

  // 页面渲染
  return props.isShow ? <div className={css.Header_Menu}>
    <div className={css.menu}>
      {
        MenuConfig.map((item, menu_index) => {
          return <div className={css.item} key={item.label}>
            <div className={`${css.label} ${m_index === menu_index ? css.label_active : ''}`}
              onClick={() => { handleClick(item.path, item.search, item.state, menu_index, -1) }}
            >
              {/* <span className={`${isContains(item.label, keyword) ? css.highlight : ''}`}>{item.label}</span> */}
              <span>{item.label}</span>
            </div>
            <div className={css.subList} style={{ display: item.isShowSubMenu == true ? 'block' : 'none' }}>
              {
                item.subMenu.map((subItem, sub_index) => {
                  return <div className={`${css.subItem} ${m_index === menu_index && s_index === sub_index ? css.subItem_active : ''}`}
                    onClick={() => { handleClick(subItem.path, subItem.search, subItem.state, menu_index, sub_index) }}
                    key={subItem.name}>
                    {/* <span className={`${isContains(subItem.name, keyword) ? css.highlight : ''}`}>{subItem.name}</span> */}
                    <span>{subItem.name}</span>
                  </div>
                })
              }
            </div>

          </div>
        })
      }
    </div>
    {/* 搜索内容框 */}
    {/* <div className={css.searchBox}>
      <input type="text" className={`${css.input} ${keyword ? css.notNull : css.phClass}`} placeholder="请输入关键字搜索"
        value={keyword} onChange={(e) => {
          setKeyword(e.target.value)
        }} />
      <img src={img_magnifier} alt="" className={css.img_magnifier} />
    </div> */}
  </div> : null
}

export default Menu