import css from './index.scss';
import React, { useEffect, useState } from 'react';
import { scrollSmoothToY } from '@utils'

const ScrollTopTool = (props) => {
  let { canScrollTop, onClickCallback } = props
  let [isShow, setIsShow] = useState(false)
  let [screenRatio, setScreenRatio] = useState(1) // 超过1屏显示‘回到顶部’组件

  useEffect(() => {
    // 如果ScrollTopTool 未手动传入canScrollTop参数，就采用document.body全局判断是否到达底部，
    if (canScrollTop !== undefined && canScrollTop !== null && typeof canScrollTop == 'boolean') {
      setIsShow(canScrollTop)
    } else {
      document.body.onscroll = () => {
        var scrollTop = document.body.scrollTop || document.documentElement.scrollTop; //已向上滚动的高度
        var clientHeight = document.documentElement.clientHeight; // 可视区域的高度
        if (scrollTop >= clientHeight * screenRatio) {
          setIsShow(true)
        } else {
          setIsShow(false)
        }
      }
    }
  }, [canScrollTop, screenRatio])
  return isShow == true ? <div className={css.ScrollTop} onClick={() => {
    if(onClickCallback && typeof onClickCallback == 'function'){
      onClickCallback()
    }else{
      scrollSmoothToY(document.body, 0)
    }
  }}></div> : null
}

export default ScrollTopTool;