import { DESIGN_DRAFT_WIDTH } from '@constants/css'

function px2vw($px){
  return $px / DESIGN_DRAFT_WIDTH * 100 + 'vw'
}

function vw2px($vw){
  return parseFloat($vw) / 100 * DESIGN_DRAFT_WIDTH
}

const cssParse = {
  px2vw,
  vw2px
}

export default cssParse