
import fetch from 'isomorphic-fetch'
import { buildParams } from '@utils/base'
// import message from '@utils/message'
import host from './host'
function checkStatus(response) {
  if ((response.status >= 200 && response.status < 300)) {
    return response;
  } else {
    return {}
  }
}

function param(obj) {
  var query = '',
    name, value, fullSubName, subName, subValue, innerObj, i;

  for (name in obj) {
    value = obj[name];

    if (value instanceof Array) {
      for (i = 0; i < value.length; ++i) {
        subValue = value[i];
        fullSubName = name + '[' + i + ']';
        innerObj = {};
        innerObj[fullSubName] = subValue;
        query += param(innerObj) + '&';
      }
    } else if (value instanceof Object) {
      for (subName in value) {
        subValue = value[subName];
        fullSubName = name + '[' + subName + ']';
        innerObj = {};
        innerObj[fullSubName] = subValue;
        query += param(innerObj) + '&';
      }
    } else if (value !== undefined && value !== null) {
      query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&';
    }
  }
  return query.length ? query.substr(0, query.length - 1) : query;
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
const request = (url, options) => {
  // loading.show();
  const defaultOptions = {
    showMsg: true, // 默认显示请求接口的错误提示
    method: 'get',
    // credentials: 'same-origin',
    credentials: 'include'
  };
  const newOptions = { ...defaultOptions, ...options };

  var headers = new Headers();
  headers.append('Accept', 'application/json');
  newOptions.headers = headers

  if (newOptions.method === 'get' || newOptions.method === 'GET') {
    // get请求
    delete newOptions.data.showMsg
    let data = buildParams(newOptions.data);
    url = `${url}${data}`;
  } else if (newOptions.method === 'POST' || newOptions.method === 'PUT') {
    // post请求，form-data参数
    newOptions.body = param(newOptions.data)
    newOptions.method = 'post';
    newOptions.headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
  } else if (newOptions.method === 'JSON') {
    // post请求，json.stringify参数
    newOptions.body = JSON.stringify(newOptions.data);
    newOptions.method = 'post';
    newOptions.headers.set('Content-Type', 'application/json;charset=utf-8');
  } else if (newOptions.method === 'FORM') {
    newOptions.method = 'post';
    newOptions.body = newOptions.data;

  }
  return new Promise((resolve, reject) => {
    fetch(host + url, newOptions)
      .then(checkStatus)
      .then(response => {
        if (response && response.json) {
          let json = response.json()
          return json
        } else {
          return response
        }
      })
      .then(data => {
        if (data) {
          if (data.errorCode == 1) {
            window.location.href = `/login/main`
          } else if (data.errorCode != 0) {
            // message.error(data.errorMsg)
          } else if (data.errorCode == 0 && newOptions.showMsg) {
            // message.success(data.errorMsg)
          }
          resolve(data)
        } else {
          resolve({})
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const buildOption = (data, type) => {
  let option = {}
  if (data.showMsg !== undefined) {
    option.showMsg = data.showMsg
  }
  option.data = data
  option.method = type
  return option
  // return request(url,option)
}
export const get = (url, data = '') => {
  let option = buildOption(data, 'GET')
  return request(url, option)
}
export const post = (url, data = {}) => {
  let option = buildOption(data, 'POST')
  return request(url, option)
}
export const postJson = (url, data = {}) => {
  let option = buildOption(data, 'JSON')
  return request(url, option)
}
export const form = (url, data) => {
  // 上传图片
  return request(url, {
    data: data,
    method: 'FORM'
  })
}
export const download = (url, data, fileName) => {
  data = buildParams(data)
  fetch(host + url + data, {
    headers: {
      'credentials': 'include',
      'Content-type': 'application/json;charset=UTF-8',
    }
  }).then(res => {
    res.blob().then(blob => {
      var filename = `${fileName}.xls`
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename);  //兼容ie10
      } else {
        var a = document.createElement('a');
        document.body.appendChild(a) //兼容火狐，将a标签添加到body当中
        var url = window.URL.createObjectURL(blob);   // 获取 blob 本地文件连接 (blob 为纯二进制对象，不能够直接保存到磁盘上)
        a.href = url;
        a.download = filename;
        a.target = '_blank'  // a标签增加target属性
        a.click();
        a.remove()  //移除a标签
        window.URL.revokeObjectURL(url);
      }
    })
  })
}

export const uploadAndDownload = (url, data, fileName) => {
  return new Promise((resolve, reject) => {

    fetch(host + url, {
      method: 'post',
      body: data,
      // data:data,
      headers: {
        'credentials': 'include',
      }
    }).then(res => {
      res.blob().then(blob => {
        var filename = `${fileName}.xls`
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, filename);  //兼容ie10
        } else {
          var a = document.createElement('a');
          document.body.appendChild(a) //兼容火狐，将a标签添加到body当中
          var url = window.URL.createObjectURL(blob);   // 获取 blob 本地文件连接 (blob 为纯二进制对象，不能够直接保存到磁盘上)
          a.href = url;
          a.download = filename;
          a.target = '_blank'  // a标签增加target属性
          a.click();
          a.remove()  //移除a标签
          window.URL.revokeObjectURL(url);
          resolve({})
        }
      })
    })
  })

}

const Request = {
  get: get,
  form: form,
  post: post,
  postJson: postJson,
  download: download,
  uploadAndDownload: uploadAndDownload,
}

export default Request;






