import React, { Component, useRef, useState, useCallback } from 'react';
import css from './index.scss';
// 导入基本swiper组件
import Swiper from "swiper";
import { withRouter } from "react-router-dom";
import imageTool from "@utils/imageTool";
import { PhotoProvider, PhotoSlider, PhotoConsumer } from 'react-photo-view';
import 'react-photo-view/dist/index.css';
import Pagination from '@component/Pagination';

class Detail_Swiper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0,
      shouldStopIn1: false,
      currBanner: '',
      swiper: null,

      viewerArray: [],
      currImgIndex: 0,
      isOpen: false
    };
    this.setSlideIndex = (index) => {
      this.setState({
        slideIndex: index
      })
    }
    this.initSwiper = () => {
      let _self = this
      setTimeout(() => {
        let tt = new Swiper("#swiper_products", {
          slidesPerView: 1,//同屏显示多少个swiper滑块
          autoplay: {
            disableOnInteraction: false,//不写这行的话，用户滑动后会默认暂停。
          },//自动轮播开启
          direction: 'horizontal',
          mousewheel: false,
          pagination: {
            el: '.swiper-pagination-h',
            clickable: true
          },
          spaceBetween: 15,// 与下一屏的空白距离
          initialSlide: 0,//默认展示第几个滑块
          loop: false,//是否开启无限轮播
          lazy: {
            loadPrevNext: true,
          },
          observer: true,//修改swiper自己或子元素时，自动初始化swiper
          observeParents: true,//修改swiper的父元素时，自动初始化swiper
          observeSlideChildren: true,
          on: {
            slideChangeTransitionEnd: function () {
              // console.log(this)
              // console.log(this.realIndex)//点击的slide索引
              // ---------------------------------------------------------------
              //  只有当loop: false,索引[realIndex]才不会变混乱，loop:true还得想办法
              // ---------------------------------------------------------------
              _self.setSlideIndex(this.realIndex)
              let { banner } = _self.props
              let { shouldStopIn1 } = _self.state
              if (this.realIndex == 0 && banner && shouldStopIn1 == true) {
                this.autoplay.stop()
                _self.setState({
                  shouldStopIn1: false
                })
              } else {
                this.autoplay.start()
              }
            }
          }
        });
        _self.setState({
          swiper: tt
        })
      }, 500) //不加延时的话 loop: true 不生效, 可能是需要等待渲染完成
    }

    this.handleViewerArray = (data) => {
      let result = []
      if (!data.photos) return result
      if (data.photos.length > 0) {
        data.photos.forEach((img, c_index) => {
          result.push({
            src: img,
            caption: data.goodsName,
            alt: data.goodsName,
            srcSet: ''
          })
        })
        return result
      } else {
        return []
      }
    }
  }
  componentDidMount() {
    this.initSwiper()
  }
  componentDidUpdate() {
    let { banner } = this.props
    let { shouldStopIn1, currBanner, swiper } = this.state
    if (swiper && banner && currBanner != banner) {
      swiper.slideTo(0)
      swiper.autoplay.stop()
      this.setState({
        shouldStopIn1: true,
        currBanner: banner
      })
    }
  }
  render() {
    let product = this.props.data
    let { slideIndex, isOpen, currImgIndex, viewerArray } = this.state

    return product.photos ? <div className={css.Detail_Swiper}>
      <div className={css.PhotoProvider} onClick={() => this.setState({
        isOpen: true
      })}>
        <PhotoProvider>
          <PhotoSlider
            photoClosable={false} // 图片点击可关闭
            maskClosable={true} // 背景可点击关闭
            bannerVisible={true} // 导航条 visible : 当前索引/图片数组长度 + 关闭按钮
            images={this.handleViewerArray(product)}
            visible={isOpen}
            onClose={() => this.setState({
              isOpen: false
            })}
            index={currImgIndex}
            onIndexChange={(index) => this.setState({
              currImgIndex: index
            })}
          />
        </PhotoProvider>
      </div>
      
      <div className={`${css.SwiperContainer} swiper-container`} id="swiper_products">
        <div className={css.pagination}>
          <Pagination
            current={slideIndex}
            totalPage={product.photos.length}
            divider={'/'}
          ></Pagination>
        </div>
        <div className={`${css.SwiperWrapper} swiper-wrapper`}>
          {
            product.photos?.map((img, img_index) => {
              return <div className={`${css.SwiperSlide} swiper-slide`} key={`product_${img_index}`}>
                <div className={css.product_item} onClick={() => {
                  this.setState({
                    isOpen: true,
                    currImgIndex: img_index
                  })
                }}>
                  <div className={css.img}>
                    <img src={img} alt=""></img>
                  </div>
                </div>
              </div>
            })
          }
        </div>
      </div>
    </div> : null
  }
}

export default withRouter(Detail_Swiper);
