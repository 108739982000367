import css from './index.scss';
import React, { Component, useRef, useState, useCallback } from 'react';
import imageTool from "@utils/imageTool";
import SectionTag from '@component/SectionTag';

// 导入基本swiper组件
import Swiper from "swiper";
import { withRouter } from "react-router-dom";

import btn_prev from '@images/Arrows/btn_prev@2x.png';
import btn_next from '@images/Arrows/btn_next@2x.png';
class Sibu_Honor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      initialSlide: 0
    };

    this.handleTurnPage = (direction) => {
      this.setState({
        initialSlide: 0
      })
    }
  }
  componentDidMount() {
    setTimeout(() => {
      new Swiper("#swiper_honor", {
        slidesPerView: 1,//同屏显示多少个swiper滑块
        autoplay: {
          disableOnInteraction: false,//不写这行的话，用户滑动后会默认暂停。
        },//自动轮播开启
        direction: 'horizontal',
        mousewheel: false,
        pagination: {
          el: '.swiper-pagination-h',
          clickable: true
        },
        spaceBetween: 15,// 与下一屏的空白距离
        initialSlide: 0,//默认展示第几个滑块
        loop: true,//是否开启无限轮播
        lazy: {
          loadPrevNext: true,
        },
        navigation: {
          prevEl: '#prev',
          nextEl: '#next'
        },
        observer: true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,//修改swiper的父元素时，自动初始化swiper
        on: {
          slideChangeTransitionEnd: function () {
            // console.log(this.realIndex)//点击的slide索引
          }
        }
      });
    }, 300) //不加延时的话 loop: true 不生效, 可能是需要等待渲染完成
  }
  render() {
    let {
      title_EN = '',
      title_CN = '',
      data = []
    } = this.props
    let row = 3
    let column = 2
    let dataSource = data
    let pageSize = row * column
    let totalPage = Math.ceil(dataSource.length / pageSize)

    return <div className={css.Sibu_Honor}>
      <div className={css.content}>
        <SectionTag title_EN={title_EN} title_CN={title_CN}></SectionTag>
        <div className={css.swiper}>
          <img className={`${css.pagination_btn} ${css.prev}`} id="prev" src={btn_prev} alt=""></img>
          <img className={`${css.pagination_btn} ${css.next}`} id="next" src={btn_next} alt=""></img>
          <div className={`${css.SwiperContainer} swiper-container`} id="swiper_honor">
            <div className={`${css.SwiperWrapper} swiper-wrapper`}>
              {
                Array.from(Array(totalPage)).map((slide, s_index) => {
                  return <div className={`${css.SwiperSlide} swiper-slide`} key={s_index}>
                    {
                      dataSource.slice(s_index * pageSize, (s_index + 1) * pageSize).map((item, i_index) => {
                        return <div className={css.renderItem} key={`${s_index}_${i_index}`}>
                          <img className={css.mainImg} src={imageTool.adjustWidth(item.certImg, 155 * 2)} alt=""></img>
                          <div className={css.title}>{item.honoraryTitle}</div>
                        </div>
                      })
                    }
                  </div>
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default withRouter(Sibu_Honor);