import css from './index.scss';
import { useHistory } from 'react-router-dom';
import defaultIcon from '@images/Arrows/right_arrow1.png';

const SeeMoreLabel = (props) => {
  let { 
    labelText = '查看更多',
    iconSrc = defaultIcon,
    href = '',
  } = props
  let history = useHistory()
  return <div className={css.SeeMoreLabel} onClick={()=>{
    history.push(href)
  }}>
    <div className={css.title}>
      <span className={css.span1}>{labelText}</span>
      <img src={iconSrc} alt="" className={css.iconSrc} />
    </div>
  </div>
}

export default SeeMoreLabel