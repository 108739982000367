import css from './index.scss';
import Header from '@component/Header';
import Footer from '@component/Footer';
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { formatSearchToJson } from '@utils';
import Request from '@utils/request';
import moment from 'moment';
import ScrollTopTool from '@component/ScrollTopTool';
import DropDownMenu from '@component/DropDownMenu';

import Sibu_Introduction from '@view/AboutSibu/Sibu_Introduction';
import Sibu_Honor from '@view/AboutSibu/Sibu_Honor';
import Sibu_FollowUs from '@view/AboutSibu/Sibu_FollowUs';


const AboutSibu = (props) => {
  let history = useHistory()
  let [allData, setAllData] = useState({})
  useEffect(() => {
    Request.get('/website/site/sibuIntroduce').then(res => {
      if (res.errorCode == 0) {
        setAllData(res.result)
      }
    })
  }, [])

  return <div className={css.AboutSibu}>
    <Header/>
    <div className={css.content}>
      <DropDownMenu currTitle={'思埠介绍'} span1={'思埠'} span2={'介绍'} attrkey={'about'}></DropDownMenu>
      <div className={css.Sibu_Introduction}>
        <Sibu_Introduction title_CN="关于我们" title_EN="ABOUT US" data={allData.companyOverviewEntity}></Sibu_Introduction>
      </div>
      <div className={css.Sibu_Honor}>
        <Sibu_Honor title_CN="思埠荣誉" title_EN="HONORS" data={allData.honoraryVoList}></Sibu_Honor>
      </div>
      <div className={css.Sibu_FollowUs}>
        <Sibu_FollowUs title_CN="关注我们" title_EN="FOLLOW US" data={{}}></Sibu_FollowUs>
      </div>
    </div>
    <ScrollTopTool></ScrollTopTool>
    <Footer></Footer>
  </div>
}
export default AboutSibu;