import css from './index.scss';

const SectionTag = (props) => {
  let {
    title_EN = '',
    title_CN = ''
  } = props
  return <div className={css.SectionTag}>
    <div className={css.title_EN}>
      {title_EN}
    </div>
    <div className={css.title_CN}>
      {title_CN}
    </div>
  </div>
}

export default SectionTag