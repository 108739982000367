import css from './index.scss';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Header from '@/component/Header';
import Footer from '@/component/Footer';
import ScrollTopTool from '@/component/ScrollTopTool';
import Request from '@utils/request';
import { scrollSmoothToY } from '@utils';
import imageTool from "@utils/imageTool";
import CommendList from "./component/CommendList";
import Partition from "./component/Partition";


const VideoCenter = (props) => {
  let history = useHistory()
  let [data, setData] = useState({})

  const getData = (cb = () => { }) => {
    Request.get('/website/site/videoCenter').then(res => {
      if (res.errorCode == 0) {
        setData(res.result)
        cb(res)
      }
    })
  }

  useEffect(() => {
    getData()
    return () => { }
  }, [])

  return <div className={css.VideoCenter}>
    <Header></Header>
    <div className={css.content}>
      {/* 推荐视频 */}
      <div className={css.playList}>
        <CommendList datalist={data.recommendVideoVoList}></CommendList>
      </div>
      {/* 视频分区 */}
      <div className={css.partition}>
        {
          data.videoListVos?.map((category, c_index) => {
            return <div className={css.category} key={'category' + category.catId}>
              <Partition data={category} maxShow={4}></Partition>
            </div>
          })
        }
      </div>
    </div>
    <ScrollTopTool></ScrollTopTool>
    <Footer></Footer>
  </div>
}
export default VideoCenter;