import VideoCenter from '@/view/VideoCenter'
import VideoDetail from '@/view/VideoCenter/detail'
import VideoList from '@/view/VideoCenter/list'

const routes_VideoCenter = [
  {
    path: '/VideoCenter',//视频中心
    component: VideoCenter,
    isCache: false
  },
  {
    path: '/video/:id',//视频详情
    component: VideoDetail,
    isCache: false
  },
  {
    path: '/videolist/:catId',//视频分区
    component: VideoList,
    isCache: false
  }
]

export default routes_VideoCenter