import css from './index.scss';
import LabelTag from '@component/LabelTag';
import SeeMoreLabel from '@component/SeeMoreLabel';
import imageTool from "@utils/imageTool";
import { useHistory } from 'react-router-dom';

const Product_slide = (props) => {
  let history = useHistory()
  return <div className={css.Product_slide}>
    <div className={css.LabelTag}>
      <LabelTag span1={props.span1} span2={props.span2}></LabelTag>
      <SeeMoreLabel href={`/brand?category=0`}></SeeMoreLabel>
    </div>
    <div className={css.content}>
      <div className={css.list}>
        {
          props?.mainData?.map((item, i_index) => {
            return <div className={css.item} key={`item_${i_index}`} onClick={()=>{
              if (!item.goodsId) return
              history.push(`/product/detail/${item.goodsId}`)
            }}>
              <div className={css.top}>
                <img src={imageTool.adjustWidth(item.mobileImage, 110 * 2)} alt="" className={css.icon} />
              </div>
              <div className={css.bot}>
                <div className={css.label}>{item.goodsName}</div>
              </div>
            </div>
          })
        }
      </div>
    </div>
  </div>
}

export default Product_slide