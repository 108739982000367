import css from './index.scss';
import { useState } from 'react';
import imageTool from "@utils/imageTool";
import SectionTag from '@component/SectionTag';
import { useLocation, useHistory, useParams, useRouteMatch } from 'react-router-dom';

const Brand_Products = (props) => {
  let {
    title_EN = '',
    title_CN = '',
    data = []
  } = props
  let history = useHistory()
  return <div className={css.Brand_Products}>
    <SectionTag title_EN={title_EN} title_CN={title_CN}></SectionTag>
    <div className={css.content}>
      <div className={css.brand_products}>
        {
          data.map((category, c_index) => {
            return <div className={css.category} key={'brand_products_category' + category.id}>
              <div className={css.label}>
                {category.catName}
              </div>
              <div className={css.products}>
                {
                  category?.goodsVoList?.map((product, p_index) => {
                    return <span className={css.product} key={'product' + product.id}>
                      <span className={css.goodsName} onClick={() => {
                        history.push(`/product/detail/${product.id}`, {
                          id: product.id
                        })
                      }}>
                        {product.goodsName}
                      </span>
                      {
                        p_index == category.goodsVoList?.length - 1 ? '' : '、'
                      }
                    </span>
                  })
                }
              </div>
            </div>
          })
        }
      </div>
    </div>
  </div>
}

export default Brand_Products


