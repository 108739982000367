import css from './index.scss';
import { useHistory } from 'react-router-dom';
import LabelTag from '@component/LabelTag';
import SeeMoreLabel from '@component/SeeMoreLabel';
import imageTool from "@utils/imageTool";

const Video_slide = (props) => {
  let history = useHistory()
  return <div className={css.Video_slide}>
    <div className={css.LabelTag}>
      <LabelTag span1={props.span1} span2={props.span2}></LabelTag>
      <SeeMoreLabel href={`/videoCenter`}></SeeMoreLabel>
    </div>
    <div className={css.content}>
      <div className={css.list}>
        {
          props?.mainData?.map((item, i_index) => {
            return <div className={css.item} key={`item_${i_index}`} onClick={() => {
              history.push('/video/' + item.id)
            }}>
              <img src={imageTool.adjustWidth(item.coverImage, 167 * 2)} alt="" className={css.cover} />
              <div className={css.video_mask} >
                <div className={css.play_button}></div>
              </div>
            </div>
          })
        }
      </div>
    </div>
  </div>
}

export default Video_slide