import css from './index.scss';
import Header from '@component/Header';
import Footer from '@component/Footer';
import DropDownMenu from '@component/DropDownMenu';
import imageTool from "@utils/imageTool";

import qrcode_sibu from '@images/QRcode/qrcode_sibu-chupin.jpg';

const Query_Quality = (props) => {
  return <div className={css.Query_Quality}>
    <Header></Header>
    <div className={css.content}>
      {/* 授权查询(0831移除) */}
      <DropDownMenu currTitle={'防伪查询'} span1={'防伪'} span2={'查询'} attrkey={'query'} showButton={false}> </DropDownMenu>
      {/* <iframe title={'防伪查询'} className={css.iframe} src="http://report.sibumbg.com/html/fangwei/#/"></iframe> */}
      <img className={css.mainImg} src={imageTool.adjustWidth(qrcode_sibu, 155 * 2)} alt=""></img>
      <div className={css.tipsBox}>
        <div>1.扫码关注“思埠集团”公众号</div>
        <div>2.进入公众号即可看到底部菜单【在线查询】，点击可看到【防伪查询】页面入口</div>
      </div>
    </div>
    <Footer></Footer>
  </div>
}


export default Query_Quality;

