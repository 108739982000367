import css from './index.scss';
import Header from '@component/Header';
import Footer from '@component/Footer';
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { formatSearchToJson } from '@utils';
import Request from '@utils/request';
import moment from 'moment';
import ScrollTopTool from '@component/ScrollTopTool';
import { is_PlayerType_H5 } from '@utils';

const News_Detail = (props) => {
  let history = useHistory()
  let location = useLocation()
  let id = formatSearchToJson(location.search).id
  let [newsDetail, setNewsDetail] = useState({})

  useEffect(() => {
    Request.get('/website/site/newsDetail', {
      id: id
    }).then(res => {
      if (res.errorCode == 0) {
        setNewsDetail(res.result)
      }
    })
  }, [id])

  useEffect(() => {
    let PlayerType_H5 = is_PlayerType_H5()
    let videos = document.getElementsByTagName('video')
    if (videos && videos.length > 0) {
      videos.forEach((video, v_index) => {
        if (PlayerType_H5) {
          video.setAttribute('x5-video-player-type', 'h5')
          video.setAttribute('x5-video-player-fullscreen', 'false')
          video.setAttribute('x5-video-orientation', 'portraint')
        }
        video.setAttribute('poster', video.src + '?x-oss-process=video/snapshot,t_0,f_png,m_fast') // 阿里云视频截帧
      })
    }
    if (PlayerType_H5) {
      window.onresize = function () {
        if (videos && videos.length > 0) {
          videos.forEach((video, v_index) => {
            if (video.paused) {
              video.style = ''
            } else {
              video.style = 'max-width: 100vw !important; position: fixed; top: 0; left: 0; z-index: 9999;'
            }
          })
        }
      }
    }
    return () => {
      window.onresize = null
    }
  }, [newsDetail])

  return <div className={css.News_Detail}>
    <Header/>
    <div className={css.content}>
      <div className={css.news_current}>
        <div className={css.titleBox}>
          <div className={css.title}>
            {newsDetail.title}
          </div>
          <div className={css.createTime}>
            {moment(newsDetail.createTime).format('YYYY-MM-DD HH:MM')}
          </div>
        </div>
        <div className={`${css.richText} ql-editor`} dangerouslySetInnerHTML={{ __html: newsDetail.detail }} />
      </div>
    </div>
    <ScrollTopTool></ScrollTopTool>
    <Footer></Footer>
  </div>
}
export default News_Detail;