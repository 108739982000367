/**
 * 滚动加载数据
 * @date    2019-11-22 02:32:48
 * @Author  likai
 */
import React, { createContext, useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
//  import EmptyData from '@modules/ListNull'
import { debounce } from '@utils'
import Request from '@utils/request';

export const ListContext = new createContext()
const App = (props, parentRef) => {
  const containerEl = useRef(null)
  let {
    children,
    // 初始化数据的开关，
    // 比如url接口的参数依赖另外一个接口返回的值，
    // 这个时候初始数据就为false，等待另外一个接口返回的值
    initSwitch = true,
    url, // 请求接口api必传
    pageDataParam = '',// 分页数据所在的字段
    defaultParams = {},
    // 父组件传入的props参数 ，作为value数据，提供给子组件使用
    value = {},
    // 列表没有数据时显示的图片和文本
    defaultEmpty = {
      showEmpty: true,
    }
  } = props

  //
  // 列表没有数据时显示的图片和文本
  let emptyData = {
    showEmpty: true,
    //  showIcon:require('@images/icons/order-none@3x.png'),
    showIcon: '',
    showText: '暂时没有相关数据',
    ...defaultEmpty
  }
  let [isSwitch, setSwitch] = useState(initSwitch)

  // 初始化数据，用于请求使用
  let [initData, setInitData] = useState({
    isMore: true,
    isLoading: false
  })
  // 列表数据，子组件渲染列表使用
  let [listData, setListData] = useState([])
  // 请求参数，默认包含分页参数，子组件可出对应参数覆盖
  let [params, setParams] = useState(() => {
    return {
      pageSize: 25,
      currentPage: 1,
      ...defaultParams
    }
  })



  // 不同业务有时候返回的数据结构不一样，在调用页面需要用到其他数据，需要返回
  let [allData, setAllData] = useState({})

  // 获取列表数据
  const getListData = () => {
    if (!initData.isMore || initData.isLoading) return
    initData.isLoading = true
    Request.get(url, params).then(({ errorCode, result }) => {

      // 分页接口返回的数据结构
      // errorCode: 0
      // errorMsg: "成功"
      // result: {totalCount: 7, pageSize: 5, totalPage: 2,…}
      // currPage: 1
      // list: [{id: 38, catId: 1, title: "耀世签手，依路相随｜331循环轻脂套装＆代言人黄圣依签约仪式圆满落幕",…},…]
      // 0: {id: 38, catId: 1, title: "耀世签手，依路相随｜331循环轻脂套装＆代言人黄圣依签约仪式圆满落幕",…}
      // 1: {id: 37, catId: 1, title: "“331”掀起轻脂新风尚丨新品发布会暨江中溯源圆满落幕！",…}
      // 2: {id: 36, catId: 1, title: "三亚年会完美落幕，新战略新力量！",…}
      // 3: {id: 35, catId: 1, title: "最新| 三亚年会两大重磅消息公布！！",…}
      // 4: {id: 34, catId: 1, title: "百亿身价老吴冒雨去摆摊？用亲身经历给你解读地摊经济",…}
      // pageSize: 5
      // totalCount: 7
      // totalPage: 2

// 以下结构需要使用 pageDataParam 参数 [分页数据所在的字段]
// catName: "原创短片"
// videoList: {totalCount: 3, pageSize: 10, totalPage: 1,…}
// currPage: 1
// list: [{coverImage: "https://imgs.meibugou.cn/day_sign/202106/a171c6076eb5b04357670b349b6cbc7f.jpg",…},…]
// pageSize: 10
// totalCount: 3
// totalPage: 1
      if (errorCode === 0) {
        let { totalPage, totalCount, list } = (pageDataParam ? result[pageDataParam] : result)
        initData.isMore = totalPage > params.currentPage // 总页数大于当前加载的页数，就可以加载下一页
        initData.isLoading = false

        params.currentPage++;
        listData = list ? listData.concat(list) : []
        setInitData(initData)
        setParams(params)
        setListData([...listData])
        setAllData(result)
      } else {
        setInitData({ isLoading: false, isMore: false })
      }
    })
  }
  useEffect(() => {
    if (isSwitch && initData.isMore && !initData.isLoading && listData.length == 0) {
      getListData()
      setSwitch(false)
    }
  }, [params, isSwitch, initData, listData])// eslint-disable-line react-hooks/exhaustive-deps


  // 滚动事件，回调方法
  const scrollListener = () => {
    var _offsetHeight = containerEl.current ? containerEl.current.offsetHeight : 0
    var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    if (topPosition(containerEl.current) + _offsetHeight - scrollTop - window.innerHeight < 100) {
      getListData()
    }
  }
  const debounceFn = debounce(scrollListener, 500)
  // 移除 window event
  const detachScrollListener = () => {
    window.removeEventListener('scroll', debounceFn);
    window.removeEventListener('resize', debounceFn);
  }
  // 添加 containerEl event
  const attachScrollListener = () => {
    window.addEventListener('scroll', debounceFn);
    window.addEventListener('resize', debounceFn);
  }
  const topPosition = (domElt) => {
    if (!domElt) {
      return 0;
    }
    return domElt.offsetTop + topPosition(domElt.offsetParent);
  }
  // 
  useEffect(() => {
    detachScrollListener()
    attachScrollListener()
    return () => {
      detachScrollListener()
    }
  }, [listData])// eslint-disable-line react-hooks/exhaustive-deps
  // 此处是子组件暴露去给父组件调用的方法
  useImperativeHandle(parentRef, () => {
    return {
      // 重置数据的时候，上一次滚动事件记录的数据还是存在的，
      // 需要先移除事件，重新监听事件，防止参数混乱
      // 刷新数据，数据重新请求接口获取
      refreshData: (obj = {}) => {
        listData = []
        setListData([])
        setParams({
          ...params,
          currentPage: 1,
          ...obj
        })
        setInitData({
          isMore: true,
          isLoading: false
        })
        setSwitch(true)
        return listData
      },

    }
  })
  children = Array.isArray(children) ? children : [children]
  return <div ref={containerEl} style={{ overflow: 'scroll' }}>
    <ListContext.Provider value={{ ...value, listData, allData }}>
      {/*listData.length>0?props.children:<EmptyData />*/}
      {

        // ListViewProvider子组件转换成list
        // list子组件最后一个组件必须是显示列表的组件，不然显示有问题，
        children.map((item, index) => {
          if ((index + 1) == children.length) {
            return emptyData.showEmpty && listData.length == 0 && !initData.isLoading && !initData.isMore ?

              //  <EmptyData key={index} image={emptyData.showIcon} text={emptyData.showText} {...emptyData}/>:item
              <div key={index}></div> : item
          }
          return item
        })

      }


    </ListContext.Provider>
  </div>
}
// App.propTypes = {
// 	url:PropTypes.string.isRequired, // 查询的数据接口，必传
// 	defaultParams:PropTypes.object,  // 查询的接口
// }
export const ListViewProvider = forwardRef(App)