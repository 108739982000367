import React, { Component, useRef, useState, useCallback } from 'react';
import css from './index.scss';
import { withRouter } from "react-router-dom";
import imageTool from "@utils/imageTool";
import { Carousel } from 'antd-mobile';
import LabelTag from '@component/LabelTag';
import SeeMoreLabel from '@component/SeeMoreLabel';
import img_nextIcon from '@images/Home/Latest_news/cut-pink-next@2x.png';

class Latest_news extends Component {

  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0,
    }
  }
  render() {
    let props = this.props
    let { mainData } = props
    return <div className={css.Latest_news}>
      <div className={css.LabelTag}>
        <LabelTag span1={props.span1} span2={props.span2}></LabelTag>
        <SeeMoreLabel href={`/news?catId=1`}></SeeMoreLabel>
      </div>
      <div className={css.content}>
        {mainData?.length > 0 ? <Carousel
          selectedIndex={0} // 手动设置当前显示的索引
          dots={false} // 是否显示面板指示点
          dotStyle={{}} // 指示点样式
          dotActiveStyle={{}} // 当前激活的指示点样式
          vertical={false} // 垂直显示
          autoplay={true} // 是否自动切换
          autoplayInterval={4000} // 自动切换的时间间隔
          infinite={true} // 是否循环播放
          cellSpacing={10} // 项目之间的间距，以px为单位
          slideWidth={1} // 手动设置项目宽度. 可以是slideWidth="20px"，也可以是相对容器的百分比slideWidth={0.8}
          // easing={'easeOutCirc'} // 缓动函数，你可以使用这里提供的其他函数
          swipeSpeed={12} // 滑动灵敏度
          // 切换面板前的回调函数
          beforeChange={(from, to) => { }}
          //切换面板后的回调函数
          afterChange={(index) => {
            this.setState({
              slideIndex: index
            })
          }}
        >
          {
            mainData.map((news, n_index) => (
              <div className={css.slideItem} key={`news_${n_index}`} onClick={() => {
                props.history.push(`/news/detail?id=${news.id}`)
              }}>
                <div className={css.item_left}>
                  <img src={imageTool.adjustWidth(news.coverImage, 100 * 2)} className={css.newsImg}></img>
                </div>
                <div className={css.item_right}>
                  <div className={css.title}>{news.title}</div>
                  <div className={css.desc}>{news.intro}</div>
                </div>
                <img src={img_nextIcon} className={css.nextIcon}></img>
              </div>
            ))
          }
        </Carousel> : null
        }
        <div className={css.swiper_pagination}>
          {
            mainData?.length > 0 ? mainData.map((news, n_index) => (
              <div className={`${css.item} ${n_index == this.state.slideIndex ? css.item_active : null}`} key={n_index}></div>
            )) : null
          }
        </div>
      </div>

    </div>
  }
}

export default withRouter(Latest_news);