import css from './index.scss';
import Header from '@component/Header';
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import { formatSearchToJson } from '@utils';
import { getYearArray } from '@utils/time';
import Request from '@utils/request';
import moment from 'moment';
import { getNewsCategoryNameById } from '@utils/api/common';
import imageTool from "@utils/imageTool";
import LabelTag from '@component/LabelTag';
import icon_arrowDown from '@images/Arrows/arrow_down@2x.png';
import ScrollTopTool from '@component/ScrollTopTool';
import Footer from '@component/Footer';
import scrollTool from "@utils/scrollTool";
import YearSelector from '@component/YearSelector';
import { scrollSmoothToY } from '@utils'


// 历年来(2021-当前年份)
let YearDataSource = [{
  label: '所有动态',
  value: '',
}]
const YearArray = [...getYearArray(2021)]
YearArray.forEach((item) => {
  YearDataSource.push({
    label: `${item}年`,
    value: item,
  })
})

const NewsList = (props) => {
  let history = useHistory()
  let location = useLocation()
  let [selectYear, setSelectYear] = useState('')
  let [list, setList] = useState([])
  let catId = formatSearchToJson(location.search).catId
  let pageSize = 5
  let [currentPage, setCurrentPage] = useState(1)
  let [totalPage, setTotalPage] = useState(0)
  let [catName, setCatName] = useState('')
  let [canScrollTop, setCanScrollTop] = useState(false)
  let [activeIndex, setActiveIndex] = useState(0)
  let [isShowSec, setIsShowSec] = useState(false)


  const getData = useCallback((isConcat) => {
    Request.get('/website/site/newsList', {
      catId: catId,
      currentPage: isConcat ? currentPage : 1,
      pageSize: pageSize,
      year: selectYear
    }).then(res => {
      if (res.errorCode == 0) {
        let { currPage, totalCount, totalPage } = res.result
        if (isConcat == true) {
          let temp = [...list, ...res.result.list]
          setList(temp)
        } else {
          setList(res.result.list)
        }
        setTotalPage(totalPage)
        setCurrentPage(currPage)
      }
    })
  }, [catId, currentPage, pageSize, list, selectYear])

  const toBottomCallback = useCallback(() => {
    if (currentPage >= totalPage) return
    setCurrentPage(currentPage + 1)
  }, [currentPage, totalPage])

  useEffect(() => {
    setTimeout(()=>{
      setCatName(getNewsCategoryNameById(catId))
    },500)
    getData(false)
    return () => { }
  }, [catId, selectYear])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentPage != 1) {
      getData(true)
    }
    return () => { }
  }, [currentPage])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const scrollFunc = (e) => {
      let scrollResult = scrollTool.scrollFunc(e.target, () => {
        toBottomCallback()
      }, () => { })
      if (!scrollResult) return
      let { scrollTop, wholeHeight, divHeight } = scrollResult
      if (scrollTop >= divHeight * 0.5) {
        setCanScrollTop(true)
      } else {
        setCanScrollTop(false)
      }
    }
    let target = document.getElementById("content")
    // 不要用 document.getElementsByClassName("content")[0] ，因为该项目使用了css模块化，用id获取元素
    if (target) {
      target.addEventListener('scroll', scrollFunc)
    }
    return () => {
      if (target) {
        target.removeEventListener('scroll', scrollFunc)
      }
    }
  }, [toBottomCallback])

  return <div className={css.NewsList}>
    <Header />
    <YearSelector
      isShow={isShowSec}
      title={'新闻年份'}
      dataSource={YearDataSource}
      initIndex={activeIndex}
      onChange={(index, value) => {
      }}
      onCancel={() => {
        setIsShowSec(false)
      }}
      onConfirm={(index, value) => {
        setActiveIndex(index)
        setSelectYear(value)
        setIsShowSec(false)
      }}></YearSelector>
    <ScrollTopTool canScrollTop={canScrollTop} onClickCallback={()=>{
      let target = document.getElementById("content")
      scrollSmoothToY(target, 0)
    }}/>
    <div className={css.top_section}>
      <div className={css.labelTag}>
        <LabelTag span1={catName?catName.slice(0, 2):''} span2={catName?catName.slice(2, 4):''}></LabelTag>
      </div>
      <div className={css.selector} onClick={() => {
        setIsShowSec(true)
      }}>
        <div className={css.year}>
          {YearDataSource[activeIndex].label}
        </div>
        <img className={css.icon} src={icon_arrowDown} alt=""></img>
      </div>
    </div>

    <div className={css.content} id='content'>
      {
        list && list.length > 0 ? <div className={css.list}>
          {
            list.map((item, i_index) => {
              return <div className={css.item} key={i_index} onClick={(e) => {
                history.push(`/news/detail?id=${item.id}`)
              }}>
                <div className={css.left}>
                  <img src={imageTool.adjustWidth(item.coverImage, 108 * 2)} alt="" className={css.img} />
                </div>
                <div className={css.right}>
                  <div className={css.title}>{item.title}</div>
                  <div className={css.intro}>{item.intro}</div>
                  <div className={css.date}>{moment(item.createTime).format('YYYY/MM/DD')}</div>
                </div>
              </div>
            })
          }
        </div> : <div className={css.list}>
          <div className={css.emptyData}>
            暂无公告
          </div>
        </div>
      }
      <div className={css.footer}>
        <Footer></Footer>
      </div>
    </div>
  </div>
}
export default NewsList;