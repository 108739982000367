import css from './index.scss';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import Header from '@/component/Header';
import Footer from '@/component/Footer';
import ScrollTopTool from '@/component/ScrollTopTool';
import Request from '@utils/request';
import { scrollSmoothToY } from '@utils';
import imageTool from "@utils/imageTool";
import VideoIntro from '../component/VideoIntro';
import LabelTag from '@component/LabelTag';
import SeeMoreLabel from '@component/SeeMoreLabel';
import VideoCom from '@component/VideoCom';

const VideoDetail = (props) => {
  let history = useHistory()
  let params = useParams()
  let Vid = params.id || ''
  let [curVideo, setCurVideo] = useState({})
  let [data, setData] = useState([])

  const List = () => {
    if (data.length == 0) return <div className={css.list}>暂无视频</div>
    return <div className={css.list}>
      {
        data.map((video, v_index) => {
          return <div className={css.item} key={video.id} onClick={() => {
            history.replace('/video/' + video.id)
            scrollSmoothToY(document.body || document.documentElement, 0)
          }}>
            <div className={css.left}>
              <img className={css.img} src={imageTool.adjustWidth(video.coverImage, 375)} alt="" />
              <div className={css.video_mask} >
                <div className={css.play_button}></div>
              </div>
            </div>
            <div className={css.right}>
              <div className={css.title}>{video.videoName}</div>
              <div className={css.infoline}>
                <div className={css.playNums}>{video.playNums}播放</div>
                <div className={css.date}></div>
              </div>
            </div>
          </div>
        })
      }
    </div>
  }

  useEffect(() => {
    Request.get('/website/site/videoCenter').then(res => {
      if (res.errorCode == 0) {
        setData(res.result.recommendVideoVoList)
      }
    })
    return () => { }
  }, [])

  useEffect(() => {
    Request.get('/website/site/videoDetail', {
      id: params.id
    }).then(res => {
      if (res.errorCode == 0) {
        setCurVideo({ ...res.result })
      }
    })
    Request.post('/website/site/incrPlayNum', {
      id: Vid
    })
    return () => { }
  }, [Vid])// eslint-disable-line react-hooks/exhaustive-deps

  if (!Vid || !curVideo.catId) return null
  return <div className={css.VideoDetail}>
    <Header></Header>
    <div className={css.content}>
      <div className={css.curVideoBox}>
        <VideoCom
          videoUrl={curVideo.videoUrl}
          posterUrl={imageTool.adjustWidth(curVideo.coverImage, 375)}
          isShowBackBtn={true}>
        </VideoCom>
      </div>

      <VideoIntro curVideo={curVideo}></VideoIntro>
      <div className={css.List}>
        <div className={css.LabelTag}>
          <LabelTag span1={'推荐'} span2={'视频'}></LabelTag>
          <SeeMoreLabel href={`/videoCenter`}></SeeMoreLabel>
        </div>
        <List />
      </div>
    </div>
    <ScrollTopTool></ScrollTopTool>
    <Footer></Footer>
  </div >
}
export default VideoDetail;