import css from './index.scss';
import { useState, useEffect } from 'react';
import imageTool from "@utils/imageTool";
import SectionTag from '@component/SectionTag';
import btn_bottom from '@images/Arrows/btn_bottom@2x.png';
import btn_top from '@images/Arrows/btn_top@2x.png';
import { getDirectScrollTop, setDirectScrollTop } from '@utils';
import { Header_Height } from '@constants/css';


const Company_Honors = (props) => {
  let {
    title_EN = '',
    title_CN = '',
    data = []
  } = props
  let defaultLen = 6
  let dataLen = data.length
  let [showLength, setShowLength] = useState(defaultLen)
  useEffect(() => {
    if (dataLen > defaultLen) {
      setShowLength(defaultLen)
    } else {
      setShowLength(dataLen)
    }
    return () => { }
  }, [dataLen, defaultLen])
  return <div className={css.Company_Honors} id="Company_Honors">
    <SectionTag title_EN={title_EN} title_CN={title_CN}></SectionTag>
    <div className={css.content}>
      <div className={css.company_honors}>
        {
          data.slice(0, showLength).map((honor, h_index) => {
            return <div className={css.company_honor_item} key={'company_honor_' + h_index}>
              <div className={css.colorBlock}></div>
              <div className={css.desc}>{honor}</div>
            </div>
          })
        }
      </div>
      {
        dataLen > defaultLen ? <img className={css.openMore} src={showLength <= defaultLen ? btn_bottom : btn_top} alt="" onClick={() => {
          if (showLength <= defaultLen) {
            setShowLength(dataLen)
          } else {
            setShowLength(defaultLen)
            window.location.href = "#Company_Honors";
            setDirectScrollTop(getDirectScrollTop() - Header_Height);
          }
        }} /> : null
      }
    </div>
  </div>
}

export default Company_Honors


