import css from './index.scss';

const Footer = (props) => {
  return <div className={css.Footer}>
    <div className={css.contact}>
      联系电话：020-22969999、020-86909999
    </div>
    <div className={css.address}>
      地址：广州市花都区新华街迎宾大道123号思埠大厦
    </div>
    <a className={css.record_number} href="https://beian.miit.gov.cn">
      粤ICP备14064065号-1
    </a>
    <div className={css.record_number}>
      Copyright © 2015-2020 广州思埠网络开发有限公司
    </div>
  </div>
}

export default Footer