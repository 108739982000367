import css from './index.scss';

const Pagination = (props) => {

  let {
    current = 0,
    totalPage = 10,
    divider = '/'
  } = props

  return <div className={css.Pagination}>
    <span>{current + 1}</span>
    <span
      className={css.divider}
      style={{ margin: totalPage >= 10 ? '0 2px' : '0 4px' }}>
      {divider}
    </span>
    <span>{totalPage}</span>
  </div>
}

export default Pagination