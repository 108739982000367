import css from './index.scss';
import { useHistory } from 'react-router-dom';
import imageTool from "@utils/imageTool";
import { HotProducts_ID, HotProducts_Name } from '@utils/constant';

const ProductList = (props) => {
  let history = useHistory()
  let { mainData = [], catId = HotProducts_ID } = props
  if (!mainData) return null

  const toProductDetail = (goodsId) => {
    history.push(`/product/detail/${goodsId}`)
  }
  const returnImage = (image) => {
    return imageTool.adjustWidth(image, 167 * 2)
  }
  const handleSellPoint = (sellPoint) => {
    return <div className={css.desc}>
      {
        sellPoint.split('\n').map((i, i_index) => {
          if (i == '') return null
          return <span key={i_index}>{i}<br /></span>
        })
      }
    </div>
  }
  return <div className={css.ProductList}>
    <div className={css.content}>
      <div className={css.list}>
        {
          mainData.map((item, i_index) => {
            return <div className={css.item} key={`item_${i_index}`} onClick={() => {
              toProductDetail(item.id)
            }}>
              <img src={returnImage(item.coverImage)} alt="" className={css.cover} />
              <div className={css.box}>
                <div className={css.title}>{item.goodsName}</div>
              </div>
            </div>
          })
        }
      </div>
    </div>
  </div>
  // return <div className={css.ProductList}>
  //   <div className={css.content}>
  //     {/* 热销产品字段和其他分类字段不一样。 */}
  //     {
  //       catId == HotProducts_ID ? <div className={css.list}>
  //         {
  //           mainData.map((item, i_index) => {
  //             return <div className={css.item} key={`item_${i_index}`} onClick={() => {
  //               toProductDetail(item.goodsId)
  //             }}>
  //               <img src={returnImage(item.image)} alt="" className={css.cover} />
  //               <div className={css.box}>
  //                 <div className={css.title}>{item.goodsName}</div>
  //                 {/* 0903: 移除产品卖点 */}
  //                 {/* {handleSellPoint(item.sellPoint)} */}
  //               </div>
  //             </div>
  //           })
  //         }
  //       </div> : <div className={css.list}>
  //         {
  //           mainData.map((item, i_index) => {
  //             return <div className={css.item} key={`item_${i_index}`} onClick={() => {
  //               toProductDetail(item.id)
  //             }}>
  //               <img src={returnImage(item.coverImage)} alt="" className={css.cover} />
  //               <div className={css.box}>
  //                 <div className={css.title}>{item.goodsName}</div>
  //                 {/* 0903: 移除产品卖点 */}
  //                 {/* {handleSellPoint(item.sellPoints)} */}
  //               </div>
  //             </div>
  //           })
  //         }
  //       </div>
  //     }
  //   </div>
  // </div>
}

export default ProductList