import css from './index.scss';
import { useState } from 'react';
import imageTool from "@utils/imageTool";
import SectionTag from '@component/SectionTag';

const General_Info = (props) => {
  let {
    title_EN = '',
    title_CN = '',
    data = {}
  } = props
  return <div className={css.General_Info}>
    <SectionTag title_EN={title_EN} title_CN={title_CN}></SectionTag>
    <div className={css.content}>
      <div className={`${css.richText} ql-editor`} dangerouslySetInnerHTML={{ __html: data }} />
    </div>
  </div>
}

export default General_Info


