import React, { Suspense, lazy, useState, useContext, useEffect, useRef } from 'react'
import { useLocation, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import css from './index.scss';
import Menu from '@component/Menu';
import { getMenuData } from '@utils/api/common';

import img_logo from '@images/Header/logo@2x.png';
import img_clock from '@images/Header/clock@2x.png';
import img_menu from '@images/Header/menu@2x.png';
import img_close_menu from '@images/Header/close-menu@2x.png';

const Header = (props) => {
  let {
    showmenu_cb = () => { }
  } = props
  let location = useLocation()
  let history = useHistory()
  let params = useParams()
  let routeMatch = useRouteMatch()

  let [isShowMenu, setIsShowMenu] = useState(false)
  let [menuData, setMenuData] = useState(null)

  useEffect(() => {
    getMenuData(false).then((data) => {
      setMenuData(data)
    })
  }, [])

  const Header_Onclick = [{
    index: 0,
    key: 'img_logo',
    callback: () => {
      history.replace('/')
      window.location.reload()
    }
  }, {
    index: 1,
    key: 'img_clock',
    callback: () => {
      history.push('/notice/list')
    }
  }, {
    index: 2,
    key: 'img_menu',
    callback: () => {
      setIsShowMenu(!isShowMenu)
      showmenu_cb()
    }
  }]

  return <div className={css.Header}>
    <div className={css.Header_Top}>
      <img className={css.img_logo} src={img_logo} alt="" onClick={Header_Onclick[0].callback}></img>
      <div className={css.right}>
        <img className={css.option} src={img_clock} alt="" onClick={Header_Onclick[1].callback}></img>
        <img className={css.option} src={isShowMenu ? img_close_menu : img_menu} alt="" onClick={Header_Onclick[2].callback}></img>
      </div>
    </div>
    <Menu data={menuData} isShow={isShowMenu} switchShow={() => setIsShowMenu(!isShowMenu)} />
  </div>
}

export default Header