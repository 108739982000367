import React, { Component, useRef, useState, useCallback } from 'react';
import css from './index.scss';
import { withRouter } from "react-router-dom";
import imageTool from "@utils/imageTool";
import { Carousel } from 'antd-mobile';
import Pagination from '@component/Pagination';

class Horizontal_swiper extends Component {

  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0,
    }
  }
  render() {
    let props = this.props
    let { mainData } = props

    return <div className={css.Horizontal_swiper}>
      <div className={css.pagination}>
        <Pagination
          current={this.state.slideIndex}
          totalPage={mainData.length}
          divider={'/'}
        ></Pagination>
      </div>
      {
        mainData?.length > 0 ? <Carousel
          selectedIndex={0} // 手动设置当前显示的索引
          dots={false} // 是否显示面板指示点
          dotStyle={{}} // 指示点样式
          dotActiveStyle={{}} // 当前激活的指示点样式
          vertical={false} // 垂直显示
          autoplay={true} // 是否自动切换
          autoplayInterval={3500} // 自动切换的时间间隔
          infinite={true} // 是否循环播放
          cellSpacing={10} // 项目之间的间距，以px为单位
          slideWidth={1} // 手动设置项目宽度. 可以是slideWidth="20px"，也可以是相对容器的百分比slideWidth={0.8}
          // easing={'easeOutCirc'} // 缓动函数，你可以使用这里提供的其他函数
          swipeSpeed={12} // 滑动灵敏度
          // 切换面板前的回调函数
          beforeChange={(from, to) => { }}
          //切换面板后的回调函数
          afterChange={(index) => {
            this.setState({
              slideIndex: index
            })
          }}
        >
          {
            mainData.map((bannerVo, b_index) => (
              <a className={css.slideItem} href={bannerVo.mobileHref} target="_blank" rel="noopener noreferrer" key={`bannerVo_${b_index}`}>
                <img src={imageTool.adjustWidth(bannerVo.image, 350 * 2)} onLoad={() => {
                  // fire window resize event to change height
                  window.dispatchEvent(new Event('resize')); // 加上这条命令后Swiper初始化高度才正常显示
                }}></img>
              </a>
            ))
          }
        </Carousel> : null
      }
    </div>
  }
}

export default withRouter(Horizontal_swiper);