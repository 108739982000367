import css from './index.scss';
import Header from '@/component/Header';
import Footer from '@/component/Footer';
import ScrollTopTool from '@/component/ScrollTopTool';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { getSelected } from '@utils';
import DropDownMenu from '@component/DropDownMenu';
import { ListViewProvider, ListContext } from '@context/listView'

const List = () => {
  let history = useHistory()
  let { listData, allData } = useContext(ListContext)
  return <div className={css.list}>
    {
      listData.map((notice, n_index) => {
        return <div className={css.n_item} key={notice.id} onClick={() => {
          if (getSelected()) return
          history.push('/notice?id=' + notice.id)
        }}>
          <div className={css.title}>{n_index + 1}. {notice.title}</div>
          <div className={css.date}>{moment(notice.createTime).format('YYYY/MM/DD')}</div>
        </div>
      })
    }
  </div>
}


const NoticeList = (props) => {
  return <div className={css.NoticeList}>
    <Header></Header>
    <div className={css.content}>
      <DropDownMenu currTitle={'思埠公告'} span1={'思埠'} span2={'公告'} attrkey={'about'}></DropDownMenu>
      <ListViewProvider url={'/website/site/noticeList'} defaultParams={{ pageSize: 25 }}>
        <List />
      </ListViewProvider>
    </div>
    <ScrollTopTool></ScrollTopTool>
    <Footer></Footer>
  </div>
}
export default NoticeList;