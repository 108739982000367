import Query_Quality from '@/view/OnlineQuery/Quality'
// import Query_Grant from '@/view/OnlineQuery/Grant'
import Query_Certificate from '@/view/OnlineQuery/Certificate'

const routes_OnlineQuery = [
  {
    path: '/query/quality',
    component: Query_Quality,
    isCache: false
  },
  // 授权查询(0831移除)
  // {
  //   path: '/query/grant',
  //   component: Query_Grant,
  //   isCache: false
  // },
  {
    path: '/query/certificate/:goodsId',
    component: Query_Certificate,
    isCache: false
  }
]

export default routes_OnlineQuery