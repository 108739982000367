import News from '@view/News'
import News_Detail from '@view/News/Detail'

const routes_News = [
  {
    path: '/news',
    component: News,
    isCache: false
  },
  {
    path: '/news/detail',
    component: News_Detail,
    isCache: false
  }
]

export default routes_News