import React, { Component, useRef, useState, useCallback } from 'react';
import css from './index.scss';
// 导入基本swiper组件
import Swiper from "swiper";
import { withRouter } from "react-router-dom";
import imageTool from "@utils/imageTool";

class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      swiper: null,
    };
    this.initSwiper = () => {
      let _self = this
      let { initIndex } = this.props
      setTimeout(() => {
        let tt = new Swiper("#swiper_options", {
          slidesPerView: 'auto',//同屏显示多少个swiper滑块
          loopedSlides: 5,
          autoplay: false,//自动轮播开启
          direction: 'horizontal',
          mousewheel: false,
          spaceBetween: 0,// 与下一屏的空白距离
          initialSlide: initIndex,//默认展示第几个滑块
          loop: false,//是否开启无限轮播
          lazy: {
            loadPrevNext: true,
          },
          observer: true,//修改swiper自己或子元素时，自动初始化swiper
          observeParents: true,//修改swiper的父元素时，自动初始化swiper
          observeSlideChildren: true,
          on: {
            slideChangeTransitionEnd: function () {
              // console.log(this.realIndex)//点击的slide索引
            }
          }
        });
        _self.setState({
          swiper: tt,
        })
      }, 1000) //不加延时的话 loop: true 不生效, 可能是需要等待渲染完成
    }
  }
  componentDidMount() {
    this.initSwiper()
  }
  componentWillUnmount() {
    let { swiper } = this.state
    if(!swiper) return 
    swiper.destroy()
    this.setState({
      swiper: null,
    })
  }
  render() {
    let { initIndex, options, onChange, history } = this.props
    let { swiper } = this.state
    return options ? <div className={css.Tabs}>
      <div className={`${css.SwiperContainer} swiper-container`} id="swiper_options">
        <div className={`${css.SwiperWrapper} swiper-wrapper`}>
          {
            options.map((option, o_index) => {
              return <div className={`${css.SwiperSlide} swiper-slide`} key={`option_${o_index}`} onClick={() => {
                swiper.slideTo(o_index)
                onChange(option, o_index)
                history.replace('/brand?category=' + option.id)
              }}>
                <div className={`${css.option_item} ${initIndex == o_index ? css.option_active : null}`}>
                  {option.catName}
                </div>
              </div>
            })
          }
        </div>
      </div>
    </div> : null
  }
}

export default withRouter(Tabs);
