import css from './index.scss';
import React, { useEffect, useState } from 'react';
import { is_PlayerType_H5 } from '@utils';
import { useHistory } from 'react-router';

import backBtn_default from '@images/Arrows/cut-btn-reback@2x.png';

const VideoCom = (props) => {
  let {
    PlayerType_H5 = is_PlayerType_H5(),
    videoUrl = '',
    posterUrl = '',
    isShowBackBtn = false,
    backBtn = backBtn_default
  } = props
  let history = useHistory()
  let [hideBackBtn, setHideBackBtn] = useState(false)

  useEffect(() => {
    if (!PlayerType_H5) return
    let myVideo = document.getElementById('video')
    if (!myVideo) return
    window.onresize = function () {
      // 返回按钮的显示逻辑
      if (isShowBackBtn) {
        if (myVideo.paused) {
          setHideBackBtn(false)
        } else {
          setHideBackBtn(true)
        }
      }
      // 下拉菜单-不能挡住视频
      let DropDownMenu = document.getElementById('DropDownMenu')
      if (DropDownMenu) {
        if (myVideo.paused) {
          DropDownMenu.style.display = 'block'
        } else {
          DropDownMenu.style.display = 'none'
        }
      }
    }
    return () => {
      window.onresize = null
    }
  }, [PlayerType_H5, isShowBackBtn])

  return <div className={css.VideoCom}>
    <video
      id="video"
      className={css.video}
      // 设置video标签的同层播放
      // ios 比较简单
      // videoElement.setAttribute("webkit-playsinline", true);
      // videoElement.setAttribute("playsinline", true);
      // video 标签在【安卓android】系统就会有各种问题，它的层级是最顶级的
      // 但 display:none 没有占位，visibility 和 z-index 不起作用, opacity 虽然不显示元素，但依旧点不到下面的元素，只能是采用定位的方式移除出屏幕外，但也只能一部分场景适合使用
      // 所以采用：通过video属性“x5-video-player-type”声明启用同层H5播放器
      // 苹果微信默认视频打开会全屏播放，使用以下属性后可禁止。
      // x5-playsinline， playsInline， webkit-playsinline， x5-video-player
      src={videoUrl}
      poster={posterUrl || videoUrl + '?x-oss-process=video/snapshot,t_0,f_png,m_fast'} // 阿里云视频截帧，参考: https://help.aliyun.com/document_detail/64555.html?spm=a2c4e.11153940.0.0.c1034696M91eGA
      controls
      autoPlay={PlayerType_H5 ? false : true}
      playsInline="" // IOS微信浏览器支持小窗内播放
      x5-playsinline=""
      webkit-playsinline=""// ios 10中设置可以让视频在小窗内播放，也就是不是全屏播放
      x-webkit-airplay="allow"
      x5-video-player-type={PlayerType_H5 ? "h5" : ""} //启用H5播放器,是wechat安卓版特性,video是单独的一个view，会覆盖在任何元素之上,据说是为了统一用户体验，加上这个属性之后，也可以让其他元素浮在video上面了
      x5-video-player-fullscreen="false" // 全屏设置，设置为 true 是防止横屏
      x5-video-orientation="portraint" // 播放器的方向， landscape横屏，portraint竖屏，默认值为竖屏
    ></video>
    {
      isShowBackBtn && !hideBackBtn ? <img className={css.backBtn}
        src={backBtn}
        alt=""
        onClick={() => {
          history.goBack()
        }} /> : null
    }
  </div>
}

export default VideoCom