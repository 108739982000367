import css from './index.scss';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Header from '@component/Header';
import Footer from '@component/Footer';
import Request from '@utils/request';
import imageTool from "@utils/imageTool";
import LabelTag from '@component/LabelTag';
import { PhotoProvider, PhotoSlider, PhotoConsumer } from 'react-photo-view';
import 'react-photo-view/dist/index.css';

const Query_Certificate = (props) => {
  let history = useHistory()
  let params = useParams()
  let goodsId = params?.goodsId
  let [certlist, setCertlist] = useState([])
  let [goodsName, setGoodsName] = useState('')

  let [viewerArray, setViewerArray] = useState([])
  let [currImgIndex, setCurrImgIndex] = useState(0)
  let [isOpen, setIsOpen] = useState(false)

  const handleViewerArray = (data) => {
    let result = []
    if (data.length > 0) {
      data.forEach((cert, c_index) => {
        result.push({
          src: cert.img,
          caption: cert.certName,
          alt: cert.certName,
          srcSet: cert.img
        })
      })
      return result
    } else {
      return []
    }

  }

  useEffect(() => {
    Request.get('/website/site/getCertListByGoodsId', {
      goodsId: goodsId
    }).then(res => {
      if (res.errorCode == 0) {
        let { certList, goodsName } = res.result
        setCertlist(certList)
        setGoodsName(goodsName)
        setViewerArray(handleViewerArray(certList))
      }
    })
  }, [goodsId])

  return <div className={css.Query_Certificate}>
    <Header></Header>
    <div className={css.content}>
      {/* <div className={css.goodsName}>
        {goodsName}
      </div> */}
      <div className={css.LabelTag}>
        <LabelTag span1={'资质'} span2={'证书'}></LabelTag>
      </div>
      <div className={css.PhotoProvider} onClick={() => setIsOpen(true)}>
        <PhotoProvider>
          <PhotoSlider
            photoClosable={false} // 图片点击可关闭
            maskClosable={true} // 背景可点击关闭
            bannerVisible={true} // 导航条 visible : 当前索引/图片数组长度 + 关闭按钮
            images={viewerArray}
            visible={isOpen}
            onClose={() => setIsOpen(false)}
            index={currImgIndex}
            onIndexChange={setCurrImgIndex}
          />
        </PhotoProvider>
      </div>
      {
        certlist?.length > 0 ? <div className={css.certificateList}>
          {
            certlist.map((cert, c_index) => {
              return <img className={css.certItem}
                src={imageTool.adjustHeight(cert.img, 1080)}
                key={cert.certId}
                alt={cert.certName}
                onClick={() => {
                  setIsOpen(true)
                  setCurrImgIndex(c_index)
                }}
              />
            })
          }
        </div> : <div className={css.emptyData}>暂无资质证书</div>
      }
    </div>
    <Footer></Footer>
  </div>
}

export default Query_Certificate

