import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import css from './index.scss';
import LabelTag from '../LabelTag';
import cssParse from '@utils/cssParse';
import dropdownIcon from '@images/Arrows/cut-btn-dropdown@2x.png';
import dropupIcon from '@images/Arrows/cut-btn-dropup@2x.png';

const optionList = {
  'about': [{
    title: '思埠介绍',
    path: '/about',
  }, {
    title: '走进思埠',
    path: '/general',
  }, {
    title: '思埠公告',
    path: '/notice/list'
  }],
  'query': [
    // 授权查询(0831移除)
    // {
    //   title: '授权查询',
    //   path: '/query/grant',
    // }, 
    {
      title: '防伪查询',
      path: '/query/quality',
    }]
}

const DropDownMenu = (props) => {
  let history = useHistory()
  let {
    currTitle = '',
    span1 = '',
    span2 = '',
    isShowOptions = false,
    showButton = true, // 是否显示下拉按钮
    attrkey = 'about', // 默认是:about, 该参数很重要
  } = props
  let [isShow, setIsShow] = useState(isShowOptions)
  let optionsArray = optionList[attrkey]
  return <div className={css.DropDownMenu} id="DropDownMenu">
    <div className={css.header}>
      <LabelTag span1={span1} span2={span2}></LabelTag>
      {
        showButton ? <img src={isShow ? dropupIcon : dropdownIcon} alt="" className={css.dropIcon} onClick={() => {
          setIsShow(!isShow)
        }} /> : null
      }

    </div>
    {
      optionsArray.length > 0 ? <div
        className={`${css.showOptions} ${isShow ? css.show : css.hide}`}
        style={{ height: isShow ? cssParse.px2vw((optionsArray.length - 1) * 45) : 0 }}>
        {
          optionsArray.map((option, o_index) => {
            if (currTitle == option.title) return null
            return <div className={css.option} key={o_index} onClick={() => {
              setIsShow(false)
              history.push(option.path)
            }}>
              {option.title}
            </div>
          })
        }
      </div> : null
    }

  </div>
}

export default DropDownMenu