import css from './index.scss';
import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import LabelTag from '@component/LabelTag';
import imageTool from "@utils/imageTool";

import img_nextIcon from '@images/Arrows/right_arrow1.png';

const Partition = (props) => {
  let {
    data,
    maxShow
  } = props
  if (!data) return null
  let {
    catId,
    catName,
    videoInnerVoList
  } = data
  return <div className={css.Partition}>
    <div className={css.header}>
      <div className={css.left}>
        {
          catName ? <LabelTag span1={catName.substr(0, 2)} span2={catName.substr(2)}></LabelTag> : null
        }
      </div>
      <div className={css.right} onClick={()=>{
        props.history.push('/videolist/' + catId)
      }}>
        <span className={css.text}>查看更多</span>
        <img className={css.more} src={img_nextIcon} alt=""></img>
      </div>

    </div>
    <div className={css.datalist}>
      {
        videoInnerVoList.map((video, v_index) => {
          if (v_index + 1 > maxShow) return null
          return <div className={css.item} key={video.id} onClick={() => {
            props.history.push('/video/' + video.id)
          }}>
            <div className={css.top}>
              <img className={css.img} src={imageTool.adjustWidth(video.coverImage, 375)} alt="" />
              <div className={css.video_mask} >
                <div className={css.play_button}></div>
              </div>
            </div>
            <div className={css.bot}>
              <div className={css.title}>{video.videoName}</div>
            </div>
          </div>
        })
      }
    </div>
  </div>
}

export default withRouter(Partition);