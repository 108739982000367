import css from './index.scss';
import LabelTag from '@component/LabelTag';

import img_icon1 from '@images/Home/Digital_business/cut-icon1@2x.png';
import img_icon2 from '@images/Home/Digital_business/cut-icon2@2x.png';
import img_icon3 from '@images/Home/Digital_business/cut-icon3@2x.png';
import img_icon4 from '@images/Home/Digital_business/cut-icon4@2x.png';


const listData = [{
  icon: img_icon1,
  label: '一手掌握',
  tips: '集成管理 一键操控'
}, {
  icon: img_icon2,
  label: '轻松推广',
  tips: '以口碑打开市场'
}, {
  icon: img_icon3,
  label: '轻松获客',
  tips: '结合大数据推广'
}, {
  icon: img_icon4,
  label: '高效转化',
  tips: '专业培训 直击痛点'
}]

const Digital_business = (props) => {
  let { mainData } = props
  return <div className={css.Digital_business}>
    <div className={css.LabelTag}>
      <LabelTag span1={props.span1} span2={props.span2}></LabelTag>
    </div>
    <div className={css.content}>
      {/* <div className={css.list}>
        {
          listData.map((item, i_index) => {
            return <div className={css.item} key={`item_${i_index}`}>
              <div className={css.left}>
                <img src={item.icon} alt="" className={css.icon} />
              </div>
              <div className={css.right}>
                <div className={css.label}>{item.label}</div>
                <div className={css.tips}>{item.tips}</div>
              </div>
            </div>
          })
        }
      </div> */}
      {
        <img className={css.banner} src={mainData.image} alt=""></img>
      }
    </div>
  </div>
}

export default Digital_business