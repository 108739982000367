
// url参数转JSON
export function formatSearchToJson(sear) {
  if (typeof sear !== "undefined") {
    sear = sear.substr(1);  //从起始索引号提取字符串中指定数目的字符
    var arr = sear.split("&"),  //把字符串分割为字符串数组
      obj = {},
      newarr = [];
    arr.forEach(function (v, i) { //数组遍历
      newarr = v.split("=");
      if (typeof obj[newarr[0]] === "undefined") {
        obj[newarr[0]] = newarr[1];
      }
    });
    return obj;
  };
}

// JSON转url参数
export function formatJsonToSearch(json) {
  if (typeof json !== "undefined") {
    var params = Object.keys(json).map(function (key) {
      return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
    }).join("&");
    return '?' + params;
  };
}

// 判断元素是否整个窗口
export function isAllViewport(element) {
  if (element == document.documentElement || element == document.body || element == window) {
    return true
  } else {
    return false
  }
}

// 判断浏览器的内核
export function myBrowser() {
  var u = navigator.userAgent; //取得浏览器的userAgent字符串
  return {
    trident: u.indexOf('Trident') > -1, //IE内核
    presto: u.indexOf('Presto') > -1, //opera内核
    webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
    gecko: u.indexOf('Firefox') > -1, //火狐内核Gecko
    mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios
    android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android
    iPhone: u.indexOf('iPhone') > -1, //iPhone
    iPad: u.indexOf('iPad') > -1, //iPad
    webApp: u.indexOf('Safari') > -1 //Safari
  };
}

// 设置整个视窗的 scrollTop [已兼容]
export function setDirectScrollTop(scroll_top) {
  if (myBrowser().trident) {
    document.documentElement.scrollTop = scroll_top;
  } else {
    document.documentElement.scrollTop = scroll_top;  // YES except for IE5(除IE5外上述浏览器都支持)
    document.body.scrollTop = scroll_top; // NO except for IE5(除IE5外上述浏览器都不支持)
    window.pageYOffset = scroll_top; // NO except for Safari(除Safari外上述浏览器都不支持)
  }
  // window.scrollTo({
  //   top: scroll_top,
  //   behavior: "smooth" // smooth(平滑滚动),instant(瞬间滚动),默认值auto('instant')
  // })  // YES except for IE(除了IE都支持)
}
// 设置整个视窗的 scrollLeft [已兼容]
export function setDirectScrollLeft(scroll_left) {
  if (myBrowser().trident) {
    document.documentElement.scrollLeft = scroll_left;
  } else {
    document.documentElement.scrollLeft = scroll_left;
    document.body.scrollLeft = scroll_left;
    window.pageXOffset = scroll_left;
  }

}
// 获取整个视窗的 scrollTop [已兼容]
export function getDirectScrollTop() {
  return document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
}
// 获取整个视窗的 scrollLeft [已兼容]
export function getDirectScrollLeft() {
  return document.documentElement.scrollLeft || document.body.scrollLeft || window.pageXOffset
}

// 获取指定元素的 scrollTop [已兼容：主要是 document.documentElement 和 document.body 在不同浏览器的区别使用]
export function getScrollTop(element) {
  if (isAllViewport(element)) {
    return getDirectScrollTop()
  } else {
    return element.scrollTop
  }
}
// 获取指定元素的 scrollLeft [已兼容：主要是 document.documentElement 和 document.body 在不同浏览器的区别使用]
export function getScrollLeft(element) {
  if (isAllViewport(element)) {
    return getDirectScrollLeft()
  } else {
    return element.scrollLeft
  }
}
// 设置指定元素的 scrollTop [已兼容：主要是 document.documentElement 和 document.body 在不同浏览器的区别使用]
export function setScrollTop(element, scroll_top) {
  if (isAllViewport(element)) {
    setDirectScrollTop(scroll_top)
  } else {
    element.scrollTop = scroll_top;
  }
}
// 设置指定元素的 scrollLeft [已兼容：主要是 document.documentElement 和 document.body 在不同浏览器的区别使用]
export function setScrollLeft(element, scroll_left) {
  if (isAllViewport(element)) {
    setDirectScrollLeft(scroll_left)
  } else {
    element.scrollLeft = scroll_left;
    console.log(element.scrollLeft)
    console.log(scroll_left)
  }
}

// 平滑垂直滚动[上下]
export function scrollSmoothToY(element, position, cb = () => { }) {
  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame = function (callback, element) {
      return setTimeout(callback, 17);
    };
  }
  // 当前滚动偏移量
  var scrollTop = getScrollTop(element);
  // 滚动step方法
  var step = function () {
    // 距离目标滚动距离
    var distance = position - scrollTop;
    // 目标滚动位置
    scrollTop = scrollTop + distance / 5;
    if (Math.abs(distance) < 1) {
      setScrollTop(element, position)
      // console.log('滚动结束')
      typeof cb == 'function' && cb()
    } else {
      setScrollTop(element, scrollTop)
      requestAnimationFrame(step);
    }
  };
  step();
};

// 平滑水平滚动[左右]
export function scrollSmoothToX(element, position, cb = () => { }) {
  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame = function (callback, element) {
      return setTimeout(callback, 17);
    };
  }
  // 当前滚动偏移量
  var scrollLeft = getScrollLeft(element);
  console.log(scrollLeft)
  // 滚动step方法
  var step = function () {
    // 距离目标滚动距离
    var distance = position - scrollLeft;
    // 目标滚动位置
    scrollLeft = scrollLeft + distance / 5;
    if (Math.abs(distance) < 1) {
      setScrollLeft(element, position)
      // console.log('滚动结束')
      typeof cb == 'function' && cb()
    } else {
      setScrollLeft(element, scrollLeft)
      requestAnimationFrame(step);
    }
  };
  step();
};

// 获取当前是否有选中内容，如果有就不进行触发函数事件
export function getSelected() {
  if (window.getSelection) {
    return window.getSelection().toString();
  } else if (document.getSelection) {
    return document.getSelection().toString();
  } else {
    var selection = document.selection && document.selection.createRange();
    if (selection.text) {
      return selection.text.toString();
    }
    return "";
  }
}

// 函数防抖
export const debounce = (fn, interval = 300) => {
  let timeout = null;
  return function () {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn.apply(this, arguments)
    }, interval)
  }
}

export const getOperatingSystem = () => {
  let ua = navigator.userAgent.toLowerCase();
  if (/iphone|ipad|ipod|ios/i.test(ua)) {
    return 'ios'
  } else if (/android/i.test(ua)) {
    return 'android'
  }
}

export const getUserAgent = () => {
  let ua = navigator.userAgent.toLowerCase();
  if (/micromessenger/i.test(ua)) { // qq浏览器或(企业)微信内置浏览器
    return 'weixin'
  } else if (/chrome/.test(ua)) {
    return 'chrome'
  } else {
    return 'others'
  }
}

export const is_PlayerType_H5 = () => {
  let OS = getOperatingSystem()
  let UA = getUserAgent()
  let PlayerType_H5 = (OS == 'android' && UA != 'weixin') ? true : false
  return PlayerType_H5
}

//是移动端返回true,否则false
export function isMobile() {
  console.log(navigator.userAgent)
  let reg = /phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|webOS|android/i
  let result = reg.test(navigator.userAgent)
  return result
}