import css from './index.scss';
import Header from '@/component/Header';
import Footer from '@/component/Footer';
import ScrollTopTool from '@/component/ScrollTopTool';
import React, { useEffect, useState, } from 'react';
import { useLocation, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Request from '@utils/request';
import Detail_Swiper from './Detail_Swiper/index';
import imageTool from "@utils/imageTool";

const Product_Detail = (props) => {
  let history = useHistory()
  let location = useLocation()
  let params = useParams()
  let [pid, setPid] = useState(params?.id)
  let [product, setProduct] = useState({})
  let [attrIndex, setAttrIndex] = useState(-1)
  let [banner, setBanner] = useState('')
  let [price, setPrice] = useState(0)

  useEffect(() => {
    Request.get('/website/site/goodsDetail', {
      goodsId: pid
    }).then(res => {
      if (res.errorCode == 0) {
        setProduct(res.result)
        setPrice(res.result.price)
      }
    })
  }, [pid])// eslint-disable-line react-hooks/exhaustive-deps


  return <div className={css.Product_Detail}>
    <Header></Header>
    <div className={css.content}>
      <div className={css.Detail_Swiper}>
        <Detail_Swiper data={product} banner={banner} onSelectItem={(result) => {
          setAttrIndex(result.AttrIndex)
          setPrice(result.Price)
        }}></Detail_Swiper>
      </div>
      <div className={css.Detail_Info}>
        <div className={css.name}>
          <span className={css.text}>
            {product.goodsName}
          </span>
        </div>
        <div className={css.sellpoint}>
          {
            product.sellPoint?.split('\n').map((i, i_index) => {
              return <span key={i_index}>{i}<br /></span>
            })
          }
        </div>
        <div className={css.price}>
          <div className={css.text}>
            <div className={css.unit}>¥</div>
            <div className={css.number}>{price}</div>
          </div>
        </div>
        {
          product.goodsAttrVoList?.length > 0 ? <div className={css.scaleList}>
            <div className={css.label}>选择规格</div>
            <div className={css.options}>
              {
                product.goodsAttrVoList?.map((scale, s_index) => {
                  return <div className={`${css.option} ${s_index == attrIndex ? css.active : null}`} key={`scale_${s_index}`} onClick={() => {
                    setAttrIndex(s_index)
                    setBanner(scale.img)
                    setPrice(scale.price)
                    if (banner) {
                      product.photos[0] = scale.img
                    } else {
                      product.photos.unshift(scale.img)
                    }
                    setProduct(product)
                  }}>
                    {scale.attrName}
                  </div>
                })
              }
            </div>
          </div> : null
        }
        <div className={css.desc}>
          <div className={css.label}>了解详情</div>
          <div className={css.text}>
            {
              product.intro?.split('\n').map((i, i_index) => {
                return <span key={i_index}>{i}<br /></span>
              })
            }
          </div>
        </div>
        <div className={css.certificate} onClick={() => {
          history.push('/query/certificate/' + product.goodsId)
        }}>
          点击查看资质证书
        </div>
      </div>
      <div className={`${css.richText} ql-editor`} dangerouslySetInnerHTML={{ __html: product.detail }} />
    </div>
    <ScrollTopTool></ScrollTopTool>
    <Footer></Footer>
  </div>
}
export default Product_Detail;