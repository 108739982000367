import css from './index.scss';
import Header from '@/component/Header';
import Footer from '@/component/Footer';
import ScrollTopTool from '@/component/ScrollTopTool';
import React, { useEffect, useState, } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Request from '@utils/request';
import moment from 'moment';
import { formatSearchToJson } from '@utils';
import { getMenuData } from '@utils/api/common';

const Sibu_Notice = (props) => {
  let history = useHistory()
  let location = useLocation()
  // 若location.search无id参数，取sessionStorage存储的菜单栏数据中的最新的公告id
  let nid = formatSearchToJson(location.search).id
  let [notice, setNotice] = useState({})

  const getData = (noticeId) => {
    Request.get('/website/site/noticeDetail', {
      id: noticeId
    }).then(res => {
      if (res.errorCode == 0) {
        setNotice(res.result)
      } else {
        history.replace('/404')
      }
    })
  }
  useEffect(() => {
    if (nid) {
      getData(nid)
    }
    else {
      getMenuData(true).then((res) => {
        getData(res.noticeId)
      }, ()=>{
        getData('')
      })
    }
  }, [nid])// eslint-disable-line react-hooks/exhaustive-deps

  return <div className={css.Sibu_Notice}>
    <Header></Header>
    <div className={css.content}>
      <div className={css.notice_current}>
        <div className={css.box}>
          <div className={css.title}>{notice.title}</div>
          <div className={css.createTime}>{moment(notice.createTime).format('YYYY-MM-DD HH:MM')}</div>
        </div>
        <div className={`${css.richText} ql-editor`} dangerouslySetInnerHTML={{ __html: notice.detail }} />
      </div>
    </div>
    <ScrollTopTool></ScrollTopTool>
    <Footer></Footer>
  </div>
}
export default Sibu_Notice;