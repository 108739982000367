import css from './index.scss'
import React, { useEffect, useState } from 'react';
import cssParse from '@utils/cssParse'

const YearSelector = (props) => {
  let {
    isShow = false,
    title = '',
    cancelText = '取消',
    confirmText = '确定',
    dataSource = [],
    initIndex = 0,
    onCancel = () => { },
    onChange = () => { },
    onConfirm = () => { },
  } = props
  let [scrollY, setScrollY] = useState(0)
  let [step, setStep] = useState(1)
  let [selectedIndex, setSelectedIndex] = useState(initIndex)
  if (!isShow) return null
  return <div className={css.component}>
    <div className={css.YearSelector}>
      <div className={css.header}>
        <div className={css.cancel_btn} onClick={() => {
          setSelectedIndex(initIndex)
          onCancel()
        }}>{cancelText}</div>
        <div className={css.title}>{title}</div>
        <div className={css.confirm_btn} onClick={() => {
          onConfirm(selectedIndex, dataSource[selectedIndex].value)
        }}>{confirmText}</div>
      </div>
      <div className={css.content}>
        <div className={css.list} id="list"
          onTouchStart={(e) => {
            setScrollY(e.changedTouches[0].clientY)
          }}
          onTouchMove={(e) => {
            if (e.changedTouches[0].clientY - scrollY < -20 * step) {
              setStep(step + 1)
              if (selectedIndex == dataSource.length - 1) return
              setSelectedIndex(selectedIndex + 1)
              onChange(selectedIndex + 1, dataSource[selectedIndex + 1].value)
            }
            if (e.changedTouches[0].clientY - scrollY > 20 * step) {
              setStep(step + 1)
              if (selectedIndex == 0) return
              setSelectedIndex(selectedIndex - 1)
              onChange(selectedIndex - 1, dataSource[selectedIndex - 1].value)
            }
          }}
          onTouchEnd={(e) => {
            setScrollY(0)
            setStep(1)
          }}
        >
          {
            dataSource.length > 0 ? dataSource.map((item, i_index) => {
              return <div className={`${css.option} ${selectedIndex == i_index ? css.option_active : ''}`}
                style={{
                  bottom: cssParse.px2vw(80 + (selectedIndex - i_index) * 40),
                  fontSize:
                    i_index >= selectedIndex + 3 || i_index <= selectedIndex - 3 ?
                      cssParse.px2vw(18) :
                      (i_index == selectedIndex + 2 || i_index == selectedIndex - 2 ?
                        cssParse.px2vw(20) :
                        (i_index == selectedIndex + 1 || i_index == selectedIndex - 1 ?
                          cssParse.px2vw(22) : ''
                        )
                      ),
                  opacity: i_index >= selectedIndex + 2 || i_index <= selectedIndex - 2 ? 0.6 : 1,
                }}
                onClick={() => {
                  onChange(i_index)
                }}
                key={i_index}>
                {item.label}
              </div>
            }) : null
          }
        </div>
      </div>
    </div>
  </div >
}

export default YearSelector