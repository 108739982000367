import css from './index.scss';
import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { DESIGN_DRAFT_WIDTH, Header_Height } from '@constants/css'

import img_nextIcon from '@images/Arrows/right_arrow1.png';
import img_close_menu from '@images/Header/close-menu@2x.png';

const handleTouchMove = (event) => {
  event.preventDefault();
};
const VideoIntro = (props) => {
  let { curVideo } = props
  let [openIntro, setOpenIntro] = useState(false)
  let [playerHeight, setPlayerHeight] = useState(0)
  if (!curVideo) return null
  // openIntro==true 禁止页面滚动
  if (openIntro) {
    document.body.addEventListener('touchmove', handleTouchMove, { passive: false });
  } else {
    document.body.removeEventListener('touchmove', handleTouchMove, { passive: false });
  }
  let player = document.getElementById('player')
  if (player) {
    player.addEventListener('canplay', function () {
      let style = window.getComputedStyle(player, null);
      let targetNum = parseFloat(style.height)
      if(isNaN(targetNum)){
        targetNum = 350
      }
      setPlayerHeight(targetNum)
    });
  }
  const box = (hasIntro) => {
    return <div className={css.Box}>
      <div className={css.title}>
        {curVideo.videoName}
      </div>
      <div className={css.others}>
        <div className={css.volume}>{curVideo.playNums} 播放</div>
        <div className={css.vertical_line}>|</div>
        <div className={css.tag}>
          {curVideo.catName}
        </div>
        {
          !hasIntro ? <div className={css.intro} onClick={() => {
            setOpenIntro(true)
          }}>
            <div className={css.text}>内容简介</div>
            <img className={css.icon} src={img_nextIcon} />
          </div> : null
        }
      </div>
      {
        hasIntro ? <div className={css.introContent}>
          {
            curVideo.intro ? curVideo.intro.split('\n').map((i, i_index) => {
              return <span key={i_index}>{i}<br /></span>
            }) : <span>暂无简介</span>
          }
        </div> : null
      }
    </div>
  }
  return <div className={css.VideoIntro}>
    {box(false)}
    <div className={`${css.introModal} ${openIntro ? css.show : css.hide}`}
      style={{ height: openIntro ? (playerHeight >= 350 ? `calc(100vh - ${350}px)` : `calc(100vh - ${Header_Height}px - ${playerHeight}px)`) : 0 }}
      >
      <div className={css.header}>
        <div className={css.title}>视频简介</div>
        <img className={css.closeBtn} src={img_close_menu} onClick={() => {
          setOpenIntro(false)
        }} />
      </div>
      {box(true)}
    </div>
  </div>
}

export default withRouter(VideoIntro);