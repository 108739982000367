function scrollFunc(target, toBottomCallback = () => { }, toTopCallback = () => { }) {
  if (!target) return
  let scrollTop = target.scrollTop,//容器上卷的高度
    wholeHeight = target.scrollHeight,//容器底部到顶部的距离
    divHeight = target.clientHeight;//容器可视区域的高度
  // console.log(scrollTop, wholeHeight, divHeight)
  // 到达底部的回调
  if (scrollTop + divHeight >= wholeHeight) {
    toBottomCallback && typeof toBottomCallback == 'function' && toBottomCallback()
  }
  // 到达顶部的回调
  if (scrollTop == 0) {
    toTopCallback && typeof toTopCallback == 'function' && toTopCallback()
  }
  return {
    scrollTop, wholeHeight, divHeight
  }
}
const scrollTool = {
  scrollFunc
}

export default scrollTool