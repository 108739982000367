import css from './index.scss';
import Header from '@/component/Header';
import { useHistory } from 'react-router-dom';

const NotFound = (props) => {
  let history = useHistory()
  return <div className={css.Home}>
    <Header></Header>
    <div className={css.NotFound}>
      <img className={css.Img404} src="https://xls-static.sibumbg.cn/5322050ffb8a401783cc2908d040d5fc.png" alt=""></img>
      <div className={css.Tips404}>暂时找不到你要浏览的内容哦</div>
      <div className={css.Btn404} onClick={()=>{history.replace('/')}}>返回首页</div>
    </div>
  </div>
}
export default NotFound;