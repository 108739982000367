import css from './index.scss';
import Header from '@component/Header';
import Footer from '@component/Footer';
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { formatSearchToJson } from '@utils';
import Request from '@utils/request';
import moment from 'moment';
import ScrollTopTool from '@component/ScrollTopTool';
import imageTool from "@utils/imageTool";
import General_Info from '@view/EnterSibu/General_Info';
import Company_Honors from '@view/EnterSibu/Company_Honors';
import Chairman_Honors from '@view/EnterSibu/Chairman_Honors';
import Enterprise_Deeds from '@view/EnterSibu/Enterprise_Deeds';
import Brand_Products from '@view/EnterSibu/Brand_Products';
import DropDownMenu from '@component/DropDownMenu';


const EnterSibu = (props) => {
  let history = useHistory()
  let [allData, setAllData] = useState({})
  useEffect(() => {
    Request.get('/website/site/workIntoSibu').then(res => {
      if (res.errorCode == 0) {
        setAllData(res.result)
      }
    })
  }, [])

  return <div className={css.AboutSibu}>
    <Header />
    <div className={css.content}>
      <DropDownMenu currTitle={'走进思埠'} span1={'走进'} span2={'思埠'} attrkey={'about'}></DropDownMenu>
      <div className={css.General_Info}>
        <General_Info title_CN="思埠概况" title_EN="SURVEY" data={allData?.companyOverviewEntity?.overview}></General_Info>
      </div>
      <div className={css.Company_Honors}>
        <Company_Honors title_CN="企业荣誉" title_EN="HONORS" data={allData?.companyHonoraryList}></Company_Honors>
      </div>
      <div className={css.Chairman_Honors}>
        <Chairman_Honors title_CN="董事长荣誉" title_EN="CHAIRMAN" data={allData?.bossHonoraryList}></Chairman_Honors>
      </div>
      <div className={css.Enterprise_Deeds}>
        <Enterprise_Deeds title_CN="企业大事记" title_EN="CHRONOLOGY" data={allData?.progressVoList}></Enterprise_Deeds>
      </div>
      <div className={css.Brand_Products}>
        <Brand_Products title_CN="品牌产品" title_EN="SUB BRAND" data={allData?.goodsCategoryVoList}></Brand_Products>
      </div>
    </div>
    <ScrollTopTool></ScrollTopTool>
    <Footer></Footer>
  </div>
}
export default EnterSibu;